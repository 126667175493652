.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
}
