@import "../../ui/media.scss";

.translation-management {
  .translation-menu {
    position: fixed;
    bottom: 40px;
    right: 60px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include tablet {
      right: 40px;
    }

    .list-buttons {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      > * {
        width: 100%;
      }
    }
  }
}
