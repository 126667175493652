@import "../../ui/media.scss";

.login-page {
  overflow: hidden;

  article > div {
    padding: 100px 0;
    display: block;
    margin: 0 auto;
    max-width: $landscape;
  }

  header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .logo {
      max-width: 300px;
      width: 100%;
    }

    .lang-selector {
      margin: 20px 0;
    }
  }

  form {
    padding: 0 0 30px 0;

    @include tablet {
      padding: 20px 0 30px 0;
    }
  }

  .input {
    margin: 0 0 10px 0;
    display: block;
  }

  .bottom-box {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    .support-box {
      box-shadow: var(--color-button-shadow);
      border: 1px solid var(--color-link);
      width: 100%;

      @include tablet {
        width: auto;
        min-width: $landscape;
      }
    }
  }
}
