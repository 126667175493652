@import "../../../../ui/media.scss";

.confirm-bank {
  .confirm-bank-text {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 32px;
    color: var(--color-text-passive);
  }

  .confirm-bank-close-button {
    width: 100%;
    max-width: $landscape;
    margin: 0 auto;
  }

  dl {
    display: flex;
    max-width: 700px;
    flex-wrap: wrap;
    font-weight: 500;
  }

  dt,
  dd {
    margin: 0;
  }

  dt {
    width: 0;
    flex: 0 0 160px;
    font-style: italic;
    color: var(--color-text-passive);
  }

  dd {
    width: 0;
    flex: 1 0 calc(100% - 160px);
    margin-bottom: 5px;
    @include truncate;
  }

  .confirm-bank-statuses {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 16px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease;
    font-weight: 500;
    background: linear-gradient(rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.4));
  }

  &.show-status {
    .confirm-bank-statuses {
      opacity: 1;
    }
  }
}
