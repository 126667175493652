@import "../../../../ui/media.scss";

.country-pricing-page {
  background-color: var(--color-progress-bar-bg);

  h1 {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;

    .flag {
      margin: 0 20px 0 0;
    }
  }
}
