.root {
  // border-top: 1px solid var(--color-snow-heavy);

  display: flex;
  flex-direction: column;
  background-color: var(--color-snow);
  border-radius: 10px;
}

.header {
  padding: var(--padding-large);
}

.store {
  border-top: 1px solid var(--color-snow-heavy);
}

.store_info {
  // padding: 20px 0;
  padding: 20px var(--padding-large);

  // border-bottom: 1px solid var(--color-snow-heavy);
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 20px;
}

.store_data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.content {
  padding: 20px var(--padding-large);
  display: flex;
}
