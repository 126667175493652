.breadcrumbs {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.breadcrumb {
  flex: 1 0 150px;
  max-width: 250px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-bg);
  box-shadow: var(--box-shadow-content), var(--box-shadow-tiny);

  font-weight: 500;
  color: var(--color-black);

  border-radius: 8px;

  transition: background-color ease 0.15s;

  &:hover {
    background-color: var(--link-alt);
  }
}

.link {
  flex: 1;
  padding: 10px 15px;

  display: flex;
  gap: 15px;
  align-items: center;
}

.icon_wrapper {
  height: 38px;
  width: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-snow-heavy);
  color: var(--color-black);
  border-radius: 50%;
}

.data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.status {
  color: var(--color-text-passive);
  //   text-align: center;
  font-size: var(--font-size-2);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  //   text-align: center;
  font-weight: 600;
  color: var(--color-black);
  border-radius: 5px;
  font-size: 1.3em;
}

.breadcumb_active {
  background-color: var(--link-alt);
  border: 1px solid var(--link);
  box-shadow: none;

  .status {
    color: var(--link);
  }

  //   background-color: #eee1ff;
  //   color: #261549;
  //   border: 1px solid #261549;
}

.creating {
  // background-color: #fde8d5;
  // color: #693708;
  color: var(--color-pending-sales-input);
  background-color: var(--color-pending-sales-input-bg);
}

.confirmation {
  background-color: var(--color-merchant-verification-bg);
  color: var(--color-merchant-verification);
}

.signing {
  background-color: var(--color-merchant-signatures-bg);
  color: var(--color-merchant-signatures);
}

.verification {
  background-color: var(--color-sales-verification-bg);
  color: var(--color-sales-verification);
}

.completed {
  background-color: var(--color-status-completed-bg);
  color: var(--color-status-completed);
}

.all {
  background-color: var(--color-snow-heavy);
  color: var(--color-black);
}

// .breadcrumb_content {
//   max-width: 100px;
// }

// .breadcrumb::after {
//   content: " ";
//   display: block;
//   width: 0;
//   height: 0;
//   border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
//   border-bottom: 50px solid transparent;
//   border-left: 30px solid hsla(34, 85%, 35%, 1);
//   position: absolute;
//   top: 50%;
//   margin-top: -50px;
//   left: 100%;
//   z-index: 2;
// }

// .breadcrumb::before {
//   content: " ";
//   display: block;
//   width: 0;
//   height: 0;
//   border-top: 50px solid transparent;
//   border-bottom: 50px solid transparent;
//   border-left: 30px solid rgb(255, 255, 255);
//   position: absolute;
//   top: 50%;
//   margin-top: -50px;
//   margin-left: 3px;
//   left: 100%;
//   z-index: 1;
// }
