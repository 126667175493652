@import "../../ui/media.scss";

.root {
  min-height: 100vh;

  --page-spacing-x: 20px;
  --page-spacing-y: 20px;

  @include desktop {
    --page-spacing-x: 60px;
    --page-spacing-y: 30px;
  }
}

.page_stripped {
  padding: var(--page-spacing-y) var(--page-spacing-x);
  max-width: $desktop;
  margin: 0 auto;
  overflow: auto;
}

.sidebar_wrapper {
  position: fixed;

  width: var(--vertical-menu-width);
  height: 100vh;

  border-right: 1px solid var(--color-snow-heavy);

  z-index: var(--z-vertical-nav);
}

.content {
  position: relative;
  overflow: hidden;
  width: 100%;

  min-height: 100vh;

  @include desktop {
    width: 100vw;
    padding-left: calc(var(--vertical-menu-width));
  }
}

.section {
  padding: var(--page-spacing-y) var(--page-spacing-x);

  max-width: $desktop-mid;
  margin: 0 auto;
  min-height: calc(100vh - var(--page-spacing-y) * 2);
  overflow-y: auto;
}

.sidebar_button {
  position: fixed;
  bottom: 40px;
  right: 16px;
  z-index: 10;

  @include landscape {
    bottom: 50px;
    right: 25px;
  }
}
