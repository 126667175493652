@import "../../ui/media.scss";

.header {
  padding: var(--padding-medium);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.form {
  padding: var(--padding-medium);

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0 0 20px 0;
  }

  dt,
  dd {
    margin: 0 0 8px 0;

    display: flex;
    align-items: center;
  }

  dt {
    width: 0;
    flex: 0 0 180px;
    font-style: italic;
    color: var(--color-text-passive);
  }

  dd {
    justify-content: flex-end;
    width: 0;
    flex: 1 0 calc(100% - 180px);
    text-align: right;
    @include truncate;

    .beacon {
      display: inline-block;
    }
  }
}
