@import "../../../ui/media.scss";

.delegate-contract-overlay {
  .delegate-confirm {
    width: 100%;
  }
  .sales-admin-list {
    max-height: 50vh;
    overflow-y: auto;
    .sales-admin-row {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      border-radius: 10px;
      background-color: var(--color-white);
      border: 1px solid var(--color-progress-border);
      position: relative;
      // margin: 0 0 10px 0;

      .sales-name {
        width: 100%;
      }

      .sales-team {
        float: right;
      }

      &.active {
        border: 2px solid var(--link);
      }

      .short-info-contact {
        color: var(--color-ghost);
      }

      .admin-row-short-info {
        flex: 1 0 auto;
        line-height: 1.2;
        min-width: 0;
        max-width: calc(100% - 80px);
        padding: 0 8px;
      }
    }
  }
}
