.root {
  &:not(:last-of-type) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-snow-heavy);
  }
}

.inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 60px;
  // align-items: center;
  // margin-bottom: 20px;
  gap: 50px;
}

.delete {
  text-align: right;
  color: var(--color-text-passive);
}
