.root {
}

.heading {
  padding: var(--padding-large);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;

  padding: var(--padding-large);
}
