@import "../../ui/media.scss";

/////////// GENERAL

.root {
  max-width: var(--fieldset-max-width);
  margin: 0 auto;
  position: relative;
}

.content {
  background-color: var(--color-bg);
  border-radius: 10px;
  box-shadow: var(--box-shadow-content);
  padding: var(--padding-large);

  overflow: hidden;
}

.expand {
  z-index: 50;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.buttonText {
  min-width: 200px;
}

/////////// HEADER

.header {
  display: flex;
  gap: 26px;
}

.titleWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

// TODO: remove important? margin 0 not working. Getting overridden by .app h4
.title {
  text-transform: uppercase;
  margin: 0 !important;
}

.index {
  position: absolute;
  top: -6px;
  color: var(--color-ghost);
}

// Perhaps this flag is an already defined component?
// Also, perhaps there already are predefined typography?
.status {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  align-self: center;
  padding: 5px 12px;
  border-radius: 5px;
  background-color: rgba(246, 234, 236, 1);
  color: #c04647;

  &.has-partial-information {
    background-color: rgba(255, 246, 213, 1);
    color: #e69900;
  }

  &.has-required-information {
    background-color: var(--link-alt);
    color: var(--color-link);
  }
}

.divider {
  flex: 1;
  border-color: var(--color-progress-border);
  border-width: 2px;
}

.headerSticky {
  position: sticky;
  top: -1px;
  z-index: 49;
  padding-top: 12px;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    top: -12px;
    z-index: -1;
    left: 0;
    right: 0;
    height: calc(100% + 12px);
    background: linear-gradient(to bottom, var(--color-progress-bar-bg) 65%, transparent);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    pointer-events: none;
  }
}

.noPadding {
  padding: 0;
}
