@import "../../../ui/media.scss";

.sales-teams {
  .member-count {
    margin-right: 20px;
  }

  .external-team-check {
    fill: var(--color-button);
    width: 30px;
    height: 30px;
    vertical-align: top;
  }
}

.sales-teams,
.edit-sales-team {
  .fieldset-header {
    background-color: #fff;
  }
  .bundle-toggle {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;

    // .toggle {
    //   flex: 0 0 45px;

    //   @include tablet {
    //     flex: 0 0 70px;
    //   }
    // }

    .bundle-row {
      padding-left: 15px;
      flex: 1 0 auto;
      max-width: calc(100% - 45px);

      @include tablet {
        flex: 1 0 auto;
        max-width: calc(100% - 70px);
      }
    }
  }
}

.preset-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .preset-toggle {
    flex: 1;

    .toggle {
      margin: 0;
    }
  }
  .preset-toggle-archived {
    background-color: var(--color-snow);
    color: var(--color-text-passive);
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 5px;
  }
}

.sales-team-fieldset {
  padding: var(--padding-large);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-content);
  border-radius: 12px;
}

.preset-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  .preset-column {
    flex: 1 1 250px;
  }

  .preset-label {
    font-weight: 500;
    color: var(--color-text-passive);
  }
}
