.support-box {
  display: flex;
  background-color: var(--link-alt);
  padding: 15px;
  border-radius: 8px;
  flex-direction: column;
  color: var(--color-link);

  a {
    font-size: var(--font-size-1);
    word-break: break-all;
  }

  span {
    font-size: var(--font-size-2);
    margin-bottom: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: "auto";
    }
  }
}
