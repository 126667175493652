@import "../../ui/media.scss";

.addressWithSearch {
  .address {
    padding: 12px;
    border-radius: 4px;
    background-color: var(--color-smoke-light);
  }

  .validationWrapper {
    display: flex;
    flex-wrap: wrap;

    margin-top: 10px;
    gap: 10px;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;

  position: relative;

  @include tablet {
    flex-direction: row;
    gap: 10px;
  }

  .searchWrapper {
    width: 100%;

    @include tablet {
      min-width: 70%;
      flex: 1;
    }
  }

  .manual_button {
    width: 100%;
    @include tablet {
    }
  }
}

.addressContainer {
  margin-top: 6px;

  @include tablet {
    margin-top: 10px;
  }
  background-color: var(--color-snow-heavy);

  border-radius: 10px;

  padding: 15px;
  gap: 15px;

  display: flex;
  align-items: center;
}

.addressContainer_fullAddress {
  background-color: var(--link-alt);
  border: 1px solid var(--color-link);
}

.closeButton {
  margin-left: auto;
}

.locationIcon {
  font-size: var(--height-interaction-mini);
  // color: var(--color-link);
}

.addressWrapper {
  flex: 1;

  display: flex;
  justify-content: space-between;

  position: relative;
  gap: 15px;

  .addressInfo {
    flex: 1;
  }

  li {
    @include truncate;
    width: 100%;
    display: block;
    min-width: 0;
  }
}

.secondAddressRow {
  font-size: var(--font-size-2);
  color: var(--color-text-passive);
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 10px;
}
