@import "../../../../ui/media.scss";

.pricing-view {
  .logo-family {
    align-items: center;
    display: inline-flex;
    margin: 0 0 16px 0;

    ul {
      display: inline-flex;
      gap: 8px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

    li {
    }
  }

  .card-column-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;

    margin: 0 0 32px;
  }

  .pricing-overview {
    li {
      margin: 20px 0;
    }
  }

  .pre-auth-fees {
    dt > i {
      line-height: 1;
      display: block;
      position: relative;
      // margin: 4px 0 0 0;
      font-size: 1.5rem;
      font-style: italic;
      color: var(--color-text-passive);
    }
  }
}
