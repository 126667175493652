.historical-stats-graph {
  svg {
    overflow: visible;
  }

  .areas {
    stroke: none;
  }

  .started,
  .sent-for-signing,
  .completed-signing,
  .completed-signoff,
  .completed-onboarding {
    stroke-width: 2px;
    fill: none;
  }

  .started {
    stroke: #74b941;
  }

  .sent-for-signing {
    stroke: #1984a5;
  }

  .completed-signing {
    stroke: #ffa600;
  }

  .completed-signoff {
    stroke: #ca4730;
  }

  .completed-onboarding {
    stroke: #6f4e7c;
  }

  .y-tick {
    rect {
      fill: var(--color-text);
    }

    text {
      text-anchor: middle;
      fill: var(--color-white);
    }
  }

  .axis {
    stroke: var(--color-smoke);
    stroke-width: 1px;
    transition: opacity 300ms ease;
  }

  .vertical-tick,
  .horizontal-tick {
    stroke: var(--color-smoke);
  }

  .x-tick,
  .label {
    text-anchor: middle;
    font-size: 0.9em;
    font-style: italic;
    fill: var(--color-ghost);
    transition: opacity 300ms ease;
  }

  &.is-pending {
    .tick,
    .label,
    .axis {
      opacity: 0;
    }
  }

  .tick {
    margin: -15px -15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    line-height: 1.3;
    border-radius: 50%;
    background-color: var(--color-steel);
    color: var(--color-white);
    position: absolute;
    font-weight: 500;
    z-index: 210;
    transition: opacity 300ms ease;
  }
}
