@import "../../ui/media.scss";

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-large);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.buttons {
  border-top: 1px solid var(--color-snow-heavy);
  padding: var(--padding-large);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 25px;
  row-gap: 10px;
}
