@import "../../ui/media.scss";

.hidden-input {
  .input-messages {
    font-size: var(--font-size-2);
    line-height: var(--line-height-s);

    position: relative;
    margin: 4px 0 0 10px;

    font-style: italic;
    color: var(--color-text-passive);
    min-height: 20px;

    @include tablet {
      min-height: 22px;
    }
  }

  &.has-error {
    .input-messages,
    .input-label {
      color: var(--color-text-error);
    }
  }
}

.hidden-input-box-variant {
  .input-messages {
    margin: 0;
    border-radius: 8px;
    padding: var(--padding-xs);
    color: var(--color-red-heavy);
    margin: 8px 0;
    background-color: var(--color-red-light);
    font-weight: 500;
  }

  .input-message-no-margins {
    margin: 0;
  }

  &.has-error {
    .input-messages,
    .input-label {
    }
  }
}
