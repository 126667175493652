.root {
}

.header {
  padding: var(--padding-large);
}

.hr {
  margin-top: 30px;
  margin-bottom: 25px;
  border-color: var(--color-snow-heavy);
}

.content {
  padding-top: 0;
  padding-left: var(--padding-large);
  padding-right: var(--padding-large);
  padding-bottom: var(--padding-large);
}

.borderY {
  border-top: 1px solid var(--color-snow-heavy);
  border-bottom: 1px solid var(--color-snow-heavy);
}
