.missing-translations-badge {
  //position: absolute;
  //left: 100%;
  //top: 0;
  margin-left: 5px;
  padding: 2px 3px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.05rem;
  border-radius: 3px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 15px;
  background-color: var(--color-notification-error-bg);
  color: var(--color-notification-error-text);
}
