.add-admin-user-overlay {
  .role-toggle {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;

    .toggle {
      margin: 0 10px 0 0;
    }
  }

  .open-edit-button {
    margin: 10px 0;
  }
}
