@import "../../../ui/media.scss";

.input {
  .input-frame {
    position: relative;
  }

  .icon {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 5px;
    // top: 5px;
    padding: 3px;
    opacity: 0;
    border-radius: 50%;

    @include tablet {
      right: 7px;
      // top: 7px;
    }

    top: 50%;
    // right: 50%;
    transform: translateY(-50%);
  }

  .icon-error {
    fill: var(--color-text-error);
  }

  .icon-checkmark {
    fill: var(--color-link);
    background-color: var(--link-alt);
  }

  .icon-disabled {
    fill: var(--color-silver);
  }

  &.has-error {
    .icon-error {
      opacity: 1;
    }
  }

  &.was-successful {
    .icon-checkmark {
      opacity: 1;
    }
  }

  &.is-pending {
    .icon-pending {
      opacity: 1;
    }
  }

  &.is-disabled,
  label.is-disabled {
    .icon-disabled {
      opacity: 1;
    }
  }

  &.has-pre-icon {
    .input-frame {
      display: flex;
    }

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  .pre-icon {
    display: flex;
    padding: 0 12px;

    height: var(--height-interaction);

    background-color: var(--color-progress-bar-bg);
    border: 1px solid var(--color-input-border);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    color: var(--color-text-passive);
    fill: var(--color-text-passive);
    align-items: center;
  }
}

.input-dropdown {
  .expand {
    opacity: 1;
  }
}

.input-dropdown {
  .expand-chevron {
    fill: var(--color-input-border);
  }

  &:hover,
  &:focus-within {
    .expand-chevron {
      fill: var(--color-link);
    }
  }

  &.has-error,
  &.was-successful,
  &.is-pending,
  &.is-disabled {
    .expand {
      opacity: 0;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

[list]::-webkit-calendar-picker-indicator {
  margin-bottom: 22px;
  margin-right: -10px;
}
