@import "../../ui/media.scss";

.historical-stats {
  margin: 20px 0 120px 0;
  padding: 20px;
  background-color: var(--color-white);
  box-shadow: 0 4px 16px -7px var(--color-ghost);
  border-radius: 10px;

  .historical-stats-graph {
    position: relative;
    height: 0;
    padding: 0 10px 45% 10px;

    svg {
      overflow: visible;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.historical-stats-legend {
  margin: 40px 0 0 0;

  @include tablet {
    display: flex;
    margin: 50px 0 0 0;
  }

  .started {
    & > span {
      background-color: #74b941;
      background-color: #fdc590;
    }
  }

  .sent-for-signing {
    & > span {
      background-color: #1984a5;
      background-color: #f7a6c6;
    }
  }

  .completed-signing {
    & > span {
      background-color: #ffa600;
      background-color: #c3aedf;
    }
  }

  .completed-signoff {
    & > span {
      background-color: #ca4730;
      background-color: #a4b5df;
    }
  }

  .completed-onboarding {
    & > span {
      background-color: #6f4e7c;
      background-color: #b1d2fb;
    }
  }

  li {
    @include tablet {
      margin: 0 30px 0 0;
      white-space: nowrap;
      @include truncate;
    }
  }

  span {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 4px 0 0;
    display: inline-block;
  }
}
