.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  margin-top: 20px;
}

.buttons {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
