.provider-logo {
  width: 38px;
  // margin: 0 10px 0 0;
  display: inline-block;
}

.vpay-logo {
  width: 25px;
  display: inline-block;
}

.logo-family {
  b {
  }
}
