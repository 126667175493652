.managed-translation {
  outline-offset: 2px;
  border-radius: 2px;
  transition: background-color 0.1s ease;
  position: relative;
  outline: 2px dashed green;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 255, 0, 0.15);
  }

  .editable-translation-variants {
    font-size: clamp(12px, 0.5em, 16px);
    position: absolute;
    top: -0.8em;
    right: -1em;
    background-color: green;
    color: white;
    font-weight: 600;
    border-radius: 50px;
    padding: 0em 0.4em 0.2em 0.4em;
  }

  &.is-missing {
    outline: 2px dashed red;

    &:hover {
      background-color: rgba(255, 0, 0, 0.15);
    }

    .editable-translation-variants {
      background-color: red;
    }
  }

  &.highlight {
    animation: highlight 0.5s ease 4;
  }

  @keyframes highlight {
    0% {
      background-color: rgba(255, 0, 0, 0);
    }
    50% {
      background-color: rgba(255, 0, 0, 0.25);
    }
    100% {
      background-color: rgba(255, 0, 0, 0);
    }
  }
}
