.root {
}

.associates {
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
