.root {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-snow-heavy);

  //   border-radius: 8px;
}

.user {
  margin: var(--padding-small) 0;
}

.name {
  font-size: var(--font-size-2);
  font-weight: 600;
  text-transform: capitalize;
}

.email {
  font-size: var(--font-size-1);
  color: var(--color-text-passive);
  line-height: var(--line-height-s);
  font-weight: 500;
  word-wrap: break-word;
}
