@import "../../../ui/media.scss";

.checkbox-buttons,
.radio-buttons {
  &.has-error {
    .checkbox-button,
    .radio-button {
      border: 1px solid var(--color-text-error);
    }
  }

  &.is-disabled {
    .checkbox-button,
    .radio-button {
      background-color: var(--color-input-bg);
      &:hover,
      &:focus,
      &:active,
      &:focus-within {
        background-color: var(--color-input-bg);
      }
    }
  }
}

.truncated {
  @include truncate;
}

.checkbox-button,
.radio-button {
  --radio-button-padding: 40px;

  position: relative;
  border: 1px solid var(--color-input-border);
  width: 100%;
  display: block;
  height: var(--height-interaction);
  line-height: calc(var(--height-interaction) - 2px);
  font-size: inherit;
  padding: 0 var(--radio-button-padding);
  z-index: 1;
  margin: -1px 0 0 0;
  cursor: pointer;
  background-color: var(--color-input-bg);
  transition: background-color 300ms ease;

  @include tablet {
    --radio-button-padding: 46px;
    padding: 0 var(--radio-button-padding);

    height: var(--height-interaction);
    line-height: calc(var(--height-interaction) - 2px);
  }

  &.is-disabled {
    color: var(--color-text-passive);
    border: 1px dashed var(--color-slate);
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      color: var(--color-slate);
      border: 1px dashed var(--color-slate);
      cursor: not-allowed;
      background-color: var(--color-input-bg);
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border: 1px solid var(--color-link);
    background-color: var(--link-alt);
    z-index: 2;
  }

  &.checked {
    background-color: var(--link-alt);
  }

  .checkbox-label,
  .radio-label {
    @include truncate;
    pointer-events: none;
    white-space: nowrap;
  }

  &.is-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0;
  }

  &.is-last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .checkbox,
  .radio {
    position: absolute;
    top: 50%;

    transform: translate(-50%, -50%);
    // top: 9px;
    left: calc(var(--radio-button-padding) / 2);
  }
}

.tablet-horizontal {
  @include tablet {
    & > .checkbox-button,
    & > .radio-button {
      width: 100%;
      display: inline-block;
      border-radius: 0;
      margin: 0;
      border-right: 0;

      &.is-first {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &.is-last {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 1px solid var(--color-input-border);
      }
    }

    & > .checkbox-button,
    & > .radio-button {
      &.length-2 {
        width: 50%;
      }
    }

    & > .checkbox-button,
    & > .radio-button {
      &.length-3 {
        width: 33.3332%;
      }
    }

    & > .checkbox-button,
    & > .radio-button {
      &.length-4 {
        width: 25%;
      }
    }

    & > .checkbox-button,
    & > .radio-button {
      &.length-5 {
        width: 20%;
      }
    }
  }
}
.horizontal {
  & > .checkbox-button,
  & > .radio-button {
    width: 100%;
    display: inline-block;
    border-radius: 0;
    margin: 0;
    border-right: 0;

    &.is-first {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.is-last {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: 1px solid var(--color-input-border);
    }
  }

  & > .checkbox-button,
  & > .radio-button {
    &.length-2 {
      width: 50%;
    }
  }

  & > .checkbox-button,
  & > .radio-button {
    &.length-3 {
      width: 33.3332%;
    }
  }

  & > .checkbox-button,
  & > .radio-button {
    &.length-4 {
      width: 25%;
    }
  }

  & > .checkbox-button,
  & > .radio-button {
    &.length-5 {
      width: 20%;
    }
  }
}
