a,
.link,
.as-link {
  color: var(--color-link);
  font-weight: 500;
  text-decoration: none;
  vertical-align: baseline;
  background-color: transparent;

  display: flex;
  align-items: center;

  &.block {
    display: block;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: var(--color-link-hovered);
    text-decoration: underline;
  }

  .icon {
    fill: var(--color-link);
    vertical-align: middle;
  }

  &.postfix {
    .icon {
      // margin: -2px 0 0 4px;
    }
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    width: 1.2em;
    stroke-width: 2px;
    margin-left: 6px;
  }

  .download-icon {
    font-size: 1em;
    margin-left: 6px;
  }
}
