.upload_container {
  border-radius: 10px;
  background-color: var(--color-snow);
  border: 1px dashed var(--color-wl-blue);
  transition: background-color 0.125s ease-in;

  label {
    margin: 0 !important;
  }
}

.header {
  display: flex;
  align-items: center;

  gap: var(--padding-medium);
  padding: var(--padding-medium);

  border-bottom: 1px dashed var(--color-wl-blue);
  // border: 1px dashed var(--color-wl-blue);
}

.body {
  padding: var(--padding-large);
}
