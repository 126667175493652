@import "../../../ui/media.scss";

.sales-users,
.edit-sales-user,
.admin-users,
.add-sales-user-modal {
  .fieldset-header {
    background-color: #fff;
  }
}

.add-sales-user-modal {
  .content {
    padding-top: 3.5rem !important;
  }

  .overlay {
    width: 800px;
  }

}
