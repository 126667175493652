.radio_button_toggle {
  legend {
    display: flex;
    font-weight: 500;
    margin-bottom: 5px;
  }

  label {
    font-weight: 600;
    height: var(--height-interaction);
    padding: 0 16px;
    transition: background-color 300ms ease, box-shadow 300ms ease;
    background-color: var(--color-button);
    // box-shadow: var(--color-button-shadow);
    color: var(--color-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;

    background-color: var(--color-white);
    color: var(--color-button-ghost);
    border: 2px solid var(--color-button-ghost);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--link-alt);
    }

    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  input:checked + label {
    background-color: var(--color-button);
    color: var(--color-white);
  }

  .help-text {
    font-size: var(--font-size-2);
    color: var(--color-text-passive);
    display: block;
    font-weight: 500;

    // line-height: 1.4;
  }
}
