@import "../../../../ui/media.scss";

.timeline .confirm-bank {
  ul {
    // border-bottom: 1px solid #999;
    margin: 0 0 20px 0;
  }

  &.hide-external-accounts {
    .external-accounts {
      display: none;
    }
  }

  .session-bank-account {
    height: auto;
    opacity: 1;
    flex-wrap: wrap;
    margin: 4px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid var(--color-progress-border);

    @include tablet {
      margin: 8px 0;
      padding: 0 0 12px 0;
    }
  }

  .beacon {
    margin: 0 10px 0 0;
  }

  .session-bank-account-holder-header,
  .session-bank-account-iban-header {
    flex: 0 0 140px;
    margin: 0 0 2px 0;
  }

  .session-bank-account-holder-value,
  .session-bank-account-iban-value {
    flex: 1 0 calc(100% - 140px);
    text-align: right;
    margin: 0 0 2px 0;
  }

  .session-bank-account-info {
    display: flex;
    flex-wrap: wrap;
    margin: 2px 0 0 0;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    & > div {
      display: flex;
    }
  }
}
