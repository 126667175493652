@import "../../../ui/media.scss";

// .filter-dates {
//   margin: 0 0 20px 0;

//   @include tablet {
//     display: flex;
//     align-items: flex-end;
//   }

//   .input {
//     margin: 0 0 10px 0;
//     display: block;

//     @include tablet {
//       margin: 0 20px 0 0;
//       flex: 0 1 auto;
//     }
//   }
// }

.historical-contracts-filter {
  .filter-button {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;

    @include landscape {
      display: inline-flex;
      width: auto;
      margin: 0 20px 0 0;
    }

    @include tablet {
      width: 140px;
    }

    &.active {
      background-color: var(--color-black);
    }
  }

  .filter-buttons {
    margin: 20px 0 30px 0;

    @include landscape {
      display: flex;
      flex-wrap: wrap;
    }

    @include tablet {
      margin: 0 0 30px 0;
    }
  }
}
