@import "../../../ui/media.scss";

.pricing {
  // .ICPP {
  //   .commercial,
  //   .pre-auth-fee,
  //   .pre-auth-fees,
  //   .surcharges {
  //     display: none;
  //   }
  // }

  .pricing-overview.desc {
    li {
      margin: 0 0 20px 0;
    }
  }

  .load-fee-template {
    h5 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.4;

      @include tablet {
        font-size: 1.7rem;
      }
    }
  }

  .preset-description {
    line-height: 1.5;
  }

  .contract-transaction-fees {
    background-color: var(--color-snow);
    padding: 36px 20px 16px 20px;
    border-radius: 10px;
    border: 1px solid var(--color-snow-heavy);
  }
}

.pricing-header {
  @include landscape {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.save-fee-template {
  @include landscape {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .button {
    @include landscape {
      margin: 28px 0 0 20px;
    }
  }
}

.fee-logo-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0 6px 0;

  .image {
    background-color: transparent;
  }
}

.logo-family {
  .image {
    background-color: transparent;
  }

  .fee-logo-type {
    display: inline-block;

    min-width: 85px;

    @include tablet {
      min-width: 90px;
    }
  }
}

.fee-logo-type {
  vertical-align: text-bottom;

  b {
    margin: 0 20px 0 0;
    line-height: 1;
    padding: 4px 6px;
    border-radius: 4px;
    color: var(--color-white);
    background-color: var(--color-text);
  }
}

.HR {
  .input.cashless-promotion {
    display: none;
  }
}

.pricing-section-padding {
  padding: var(--padding-large);
}
