@import "../../ui/media.scss";

.contract-ingress {
  padding: 60px 0 0 0;
}

.contract-edit {
  min-height: 100vh;
  max-width: var(--fieldset-max-width);
  margin: 0 auto;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 50px;

  @include desktop {
    gap: 100px;
  }
}

.delete-contract,
.preview-contract,
.send-for-confirmation {
  margin: 0 auto 10px auto;
  max-width: $tablet;
}

.delete-contract {
  margin: 40px auto;
}
