@import "../../../ui/media.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;

  h4 {
    margin-bottom: 20px;
  }

  .data_list {
    font-weight: 500;
    display: grid;
    grid-template-columns: 200px 1fr;

    dd {
      margin: 0 0 10px 0;
      color: var(--color-text-passive);
    }

    dt {
      text-align: right;
    }
  }
}

.columns {
  display: grid;
  grid-template-columns: 1fr;

  gap: 20px;
  margin-bottom: 16px;

  border-radius: 12px;
  overflow: hidden;
  flex-wrap: wrap;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }
}

.columns-no-padding {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  margin-top: 20px;
  overflow: hidden;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
}

.questions {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.passport,
.selfie {
  border-radius: 12px;
  overflow: hidden;
  display: grid;
  align-items: center;
}

.actions {
  margin-top: auto;
}

.spanColumns {
  grid-column: 1 / -1;
}

.idCardInputsColumn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.securityMarkModal {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    order: 3;
  }
}

.view {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reviewStatus {
  font-weight: 600;
  border-radius: var(--border-radius-button);
  // text-transform: uppercase;

  align-self: flex-end;
  padding: 6px 18px;
}

.statusCompleted {
  background-color: var(--link-alt);
  color: var(--color-link);
}

.statusWaiting {
  background-color: rgba(255, 246, 213, 1);
  color: #e69900;
}
