@import "../../ui/mixins.scss";

$height: 38px;
$border-radius: 10px;

.root {
  min-width: fit-content;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 20px;
  padding: 10px;
  border-radius: $border-radius;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  gap: 8px;
}

.page_button {
  position: relative;

  box-shadow: var(--box-shadow-tiny);
  background-color: var(--color-bg);

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  height: $height;
  width: $height;
  color: var(--color-text);
  border-radius: $border-radius;

  transition: 0.15s ease-in background-color;

  &:hover {
    background-color: var(--color-snow);
  }
}

.selected_page {
  position: absolute;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  height: $height;
  width: $height;
  border-radius: $border-radius;

  background-color: var(--color-link);
  color: white;
}

.navigation_button {
  display: flex;
  gap: 10px;
}
