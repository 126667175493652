.visible-component {
  opacity: 0;
  transition: opacity 300ms ease;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

.visible-component-no-display {
  display: none;

  &.show {
    display: block;
    pointer-events: all;
  }
}
