@import "../../../../../ui/media.scss";

.root {
}

.section {
  margin-bottom: 30px;
  background-color: var(--color-snow);
  border-radius: var(--border-radius-content);
  overflow: hidden;
}

.heading {
  padding: var(--padding-large);
}

.content {
  padding-top: 0;
}

.terminal_summaries {
  padding: 0;
}

.box {
  padding: 0 var(--padding-large) var(--padding-large) var(--padding-large);
}

.device_summary_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  // gap: 10px;
  @include tablet {
    flex-direction: column;
  }

  li {
    flex: 1;
  }
}
