.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.warning_box {
  border-radius: var(--border-radius-button);
  color: var(--color-red-heavy);
  font-weight: 500;
}
