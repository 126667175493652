.root {
  position: fixed;
  z-index: var(--z-backdrop);
  min-height: 100vh;
  min-width: 100vw;
}

.dark {
  background-color: rgba(0, 0, 0, 0.4);
}

.blur {
  background-color: rgba(255, 255, 255, 0.9);
}
