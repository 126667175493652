@import "../../ui/mixins.scss";

.root {
  position: relative;
  border-radius: var(--border-radius-content);
}

.content {
  height: 100%;
}

.color_style_default {
  @include content-style;
}

.color_style_grey {
  background-color: var(--color-snow);
}

.color_style_completed {
  background-color: var(--link-alt);
  border: 1px solid var(--color-link);
}

.header {
  border-bottom: 1px solid var(--color-snow-heavy);
}

.no_padding {
  padding: 0;
}

.padding_xs {
  padding: var(--padding-xs);
}

.padding_s {
  padding: var(--padding-medium);
}

.padding_md {
  padding: var(--padding-large);
}

.expand {
  z-index: 50;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.buttonText {
  display: flex;
  align-items: center;
}
