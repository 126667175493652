.dark-theme {
  .icon-external {
    stroke: var(--color-dark-link);
  }
}

.icon {
  width: 24px;
  height: 24px;
}

a.as-button,
.button,
.action {
  .icon {
    width: 24px;
    height: 24px;
    // fill: var(--color-white);
  }

  &.ghost {
    .icon {
      fill: var(--color-link);
    }
  }
}

.icon.close {
  line {
    stroke: var(--color-white);
    stroke-width: 2px;
  }

  &.ghost {
    stroke: var(--color-link);
  }
}

a.ghost.external-link {
  .icon-external {
    stroke: var(--color-link);
    fill: transparent;
  }
}
