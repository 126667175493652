@import "../../ui/media.scss";

.position {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;

  width: 90%;
  max-width: calc(var(--fieldset-max-width) + 50px);

  @include desktop-mid {
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% + calc(var(--vertical-menu-width) / 2)), -50%);
  }
}

.overlay {
  position: relative;

  width: 100%;
  padding: 0;

  background-color: var(--color-content);
  box-shadow: var(--box-shadow-modal);
  border-radius: 15px;

  border: none;
}

.content {
  max-height: calc(100vh - 90px);

  width: inherit;
  padding: var(--padding-large);
  overflow-y: auto;
}

.noOverflowScroll {
  overflow: auto;
  padding: 0;
}

.closeButton {
  position: absolute;
  right: -12px;
  top: -12px;
  background-color: var(--color-bg);
  z-index: 300;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #516481;
  color: #516481;

  padding: 0;

  &:hover {
    border: 2px solid var(--color-link);
    color: var(--color-link);
    background-color: var(--color-bg);
  }

  @include landscape {
    right: -15px;
    top: -15px;
  }
}

.noPadding {
  padding: 0;
}

.hasError {
  border: 2px solid var(--color-red-heavy);
  color: var(--color-red-extra-heavy);
}

.overlaySmall {
  max-width: calc(var(--fieldset-max-width) - 200px);
}

.overlayLarge {
  max-width: calc(var(--fieldset-max-width) + 200px);
}
