.progress {
  .progress-pending-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .progress-percentage {
    font-weight: 500;
    color: var(--color-text-passive);
  }

  .progress-pending-bar-bg {
    flex: 1;
    height: 7px;
    background-color: var(--color-progress-bar-bg);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }

  .progress-pending-bar-bg-white {
    background-color: white;
  }

  .progress-pending-bar-overlay {
    height: 7px;
    border-radius: 30px;
    background-color: var(--color-link);
    transition: transform 300ms ease;
  }
}
