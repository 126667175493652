@import "./ui/media.scss";

// 8:28
// but I think for us the only impact is to apply #007B7C
// to our buttons #46BEAA
// for hover and #005962
// for pressed
// 8:29
// and #F5F6FA
// for backgrounds

// https://marvelapp.com/styleguide/design/color-scheme

:root {
  --height-interaction-tiny: 18px;
  --height-interaction-mini: 24px;
  --height-interaction-small: 30px;
  --height-interaction: 38px;
  --height-header: 60px;
  --height-header-tablet: 60px;

  --color-pure-black: #000;
  --color-black: #1f2d3d;
  --color-steel: #273444;
  --color-slate: #3c4858;
  --color-ghost: #646f80;
  --color-silver: #8492a6;
  --color-white: #fff;
  --color-content: var(--color-white);

  --color-smoke: #d3dce6;
  --color-smoke-light: #e0e6ed;
  --color-smoke-heavy: #c0ccda;

  --color-snow: #f5f6fa;
  --color-snow-light: #f9fafc;
  --color-snow-heavy: #e5e9f2;

  --color-green: #2eb56a;
  --color-green-light: #29eb7f;
  --color-green-heavy: #0f9f4f;

  --color-blue-extra-light: #c9dffa;
  --color-blue: #1fb6ff;
  --color-blue-light: #9edbfa;
  --color-blue-heavy: #009eeb;
  --color-blue-extra-heavy: #082f53;

  --color-yellow: #ffc82c;
  --color-yellow-light: #ffd55f;
  --color-yellow-heavy: #f8b700;

  --color-delete-light: #ffeff2;
  --color-delete-heavy: #fc607d;
  --color-done-light: #c9f2be;
  --color-done-heavy: #73b85f;

  --color-red: #df7676;
  --color-red-light: #f6eaec;
  --color-red-heavy: #c04647;
  --color-red-extra-heavy: #832122;

  --color-warning-light: #fff6d5;
  --color-warning: #f8b700;
  --color-warning-heavy: #ef9011;
  --color-warning-extra-heavy: #ab622e;
  --color-warning-text: #3b2311;

  --color-tag: #145a8c;
  --link-alt: #e5f2f4;
  --link: #007b7c;
  --link-hovered: #46beaa;
  --link-shadow: #284646;
  --link-alt-divider: #c7e0e0;

  --color-wl-yellow: #ffeb78;
  --color-wl-turquoise: #66dbf8;
  --color-wl-pink: #f08791;
  --color-wl-blue: #005a8c;

  --color-pending-sales-input-bg: #95d4e4;
  --color-pending-sales-input: #0f2d34;

  --color-merchant-verification-bg: #fff2a7;
  --color-merchant-verification: #492303;

  --color-merchant-signatures-bg: #ffd778;
  --color-merchant-signatures: #492303;

  --color-sales-verification-bg: #85e4d4;
  --color-sales-verification: #013c3c;

  --color-status-completed-bg: var(--link);
  --color-status-completed: white;

  --color-info-light: #e6f0ff;
  --color-info: #006ca6;
  --color-info-text: #003049;

  --color-success-text: #004a4b;
  --color-success: #64ae4f;

  --color-ecom-bg: #fafdff;
  --color-instore-bg: #ffeff5;

  --z-backdrop: 100;
  --z-vertical-nav: 150;
  --z-dialog: 200;

  --vertical-menu-width: 275px;

  --fieldset-max-width: 950px;

  --border-radius-button: 8px;
  --border-radius-content: 12px;

  --box-shadow-modal: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px,
    rgba(17, 17, 26, 0.1) 0px 24px 80px;

  --box-shadow-content: rgba(145, 158, 171, 0.16) 0 10px 50px;
  --box-shadow-small: 0 2px 4px rgba(0, 0, 0, 0.16);

  --box-shadow-tiny: 0 1px 1px rgba(0, 0, 0, 0.12);

  --font-size-1: 1.2rem;
  --font-size-2: 1.3rem;
  --font-size-3: 1.5rem; // default
  --font-size-4: 1.6rem;
  --font-size-5: 2rem;
  --font-size-6: 2.3rem;
  --font-size-7: 2.7rem;
  --font-size-8: 3.2rem;

  --line-height-s: 1.5;
  --line-height-default: 1.6;

  --padding-xs: 8px;
  --padding-small: 12px;
  --padding-medium: 18px;
  --padding-large: 25px;

  --interaction-size: 20px;

  --section-padding: 10px;

  @include tablet {
    --vertical-menu-width: 290px;

    --height-interaction-tiny: 22px;
    --height-interaction-mini: 28px;
    --height-interaction-small: 34px;
    --height-interaction: 42px;

    --font-size-1: 1.3rem;
    --font-size-2: 1.4rem;
    --font-size-3: 1.6rem;
    --font-size-4: 1.8rem;
    --font-size-5: 2.2rem;
    --font-size-6: 2.5rem;
    --font-size-7: 3rem;
    --font-size-8: 3.5rem;

    --padding-xs: 10px;
    --padding-small: 16px;
    --padding-medium: 25px;
    --padding-large: 32px;
    --section-padding: 20px;

    --interaction-size: 24px;
  }

  @include desktop-plus {
    --padding-medium: 30px;
    --padding-large: 36px;
  }
}

.light-theme {
  --color-divider: var(--color-snow);
  --color-bg: var(--color-white);
  --color-text: var(--color-black);
  --color-text-passive: var(--color-ghost);
  --color-text-error: var(--color-red-heavy);
  --color-text-success: var(--color-green-heavy);
  --color-link: var(--link);
  --color-link-hovered: var(--link);
  --color-button: var(--link);
  --color-button-hovered: var(--link-hovered);
  --color-button-shadow: 0 4px 16px -7px var(--link-shadow);
  --color-button-shadow-transparent: 0 4px 16px -7px transparent;
  --color-submenu-shadow: 0 0 10px -3px var(--color-pure-black);
  --color-button-ghost: var(--link);
  --color-progress-bar-bg: var(--color-snow);
  --color-progress-border: var(--color-smoke-heavy);
  --color-input-border: var(--color-silver);
  --color-input-bg: var(--color-white);
  --color-table-header: var(--color-snow-light);

  --color-delete-bg: var(--color-delete-light);
  --color-delete-text: var(--color-delete-heavy);

  --color-notification-error-bg: var(--color-delete-heavy);
  --color-notification-error-text: var(--color-white);

  --color-notification-done-bg: var(--color-done-heavy);
  --color-notification-done-text: var(--color-white);

  --color-text-placeholder: #8f9499;
}

.dark-theme {
  --color-divider: var(--color-steel);
  --color-bg: var(--color-slate);
  --color-text: var(--color-white);
  --color-text-passive: var(--color-smoke-heavy);
  --color-text-error: var(--color-red-light);
  --color-text-success: var(--color-green-light);
  --color-link: var(--color-blue-light);
  --color-link-hovered: var(--color-blue-light);
  --color-button: var(--link);
  --color-button-shadow: 0 2px 10px -2px var(--color-pure-black);
  --color-submenu-shadow: 0 2px 10px -2px var(--color-pure-black);
  --color-button-shadow-transparent: 0 2px 10px -2px transparent;
  --color-button-hovered: var(--link-hovered);
  --color-button-ghost: var(--color-blue-light);
  --color-progress-bar-bg: var(--color-ghost);
  --color-progress-border: var(--color-bg);
  --color-input-border: var(--color-ghost);
  --color-input-bg: var(--color-black);
  --color-table-header: var(--color-steel);

  --color-delete-bg: var(--color-delete-heavy);
  --color-delete-text: var(--color-white);

  --color-notification-error-bg: var(--color-red-heavy);
  --color-notification-error-text: var(--color-white);

  --color-notification-done-bg: var(--color-green);
  --color-notification-done-text: var(--color-white);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
ol,
ul {
  margin: 0;
  padding: 0;
}

.app {
  background-color: var(--color-snow);
  color: var(--color-text);
  min-height: 100vh;
  overflow: hidden;
}

ol,
ul {
  list-style: none;

  &.desc {
    list-style: disc;
    margin-left: 1em;
  }
}

article {
  max-width: $desktop-mid;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.text-passive {
  color: var(--color-text-passive);
}

.text-disabled {
  color: var(--color-text-placeholder);
}

.color-wl-green {
  color: #007b7c;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.relative {
  position: relative;
}

.landscape-and-up {
  display: none;

  @include landscape {
    display: block;
  }
}

.tablet-and-up {
  display: none;

  @include tablet {
    display: block;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);

    @include tablet {
      flex: 0 0 calc(50% - 25px);
      max-width: calc(50% - 25px);
    }
  }

  & > .full {
    flex: 0 0 100%;
    max-width: 100%;

    @include tablet {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.tablet-columns {
  @include tablet {
    flex-direction: row;
    gap: 0;

    display: flex;

    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > .column,
  & > div {
    @include tablet {
      flex: 0 0 calc(50% - 25px);
    }
  }
}

.double-auto-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  column-gap: 25px;
  row-gap: 20px;

  @include tablet {
    column-gap: 40px;
    row-gap: 30px;
  }
}

.triple-auto-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 25px;
  row-gap: 20px;

  @include tablet {
    column-gap: 40px;
    row-gap: 30px;
  }
}

.hide {
  display: none;
}

button {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  font-size: inherit;
  color: var(--color-button);
  font-family: inherit;
  // font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

hr {
  display: block;
  border: none;
  border-top: 1px solid var(--color-divider);
  height: 0;
  margin: 40px 0;
}

.invisible {
  visibility: hidden;
}

code {
  font-family: monospace;
}

.landscape-wrapper {
  max-width: $landscape;
  margin: 0 auto;
}

.tablet-wrapper {
  max-width: $tablet;
  margin: 0 auto;
}

.compact-input {
  .input-messages {
    display: none;
  }
}

.as-ghost {
  background-color: var(--color-bg);
  border: 1px solid var(--color-link);
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 10px 14px 50px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: var(--color-button-shadow);
  transition: box-shadow 300ms ease;

  &:hover,
  &:focus,
  &:active {
    box-shadow: var(--color-button-shadow-transparent);
  }

  &.is-disabled {
    box-shadow: var(--color-button-shadow-transparent);
    pointer-events: none;
    border: 1px solid var(--color-progress-border);
  }
}

.associated-profiles {
  margin: 0 0 24px 0;
}

.m-top-10 {
  margin: 10px 0 0 0;
}

.m-top-20 {
  margin: 20px 0 0 0;
}

.m-top-30 {
  margin: 30px 0 0 0;
}

.m-top-40 {
  margin: 40px 0 0 0;
}

.m-top-50 {
  margin: 50px 0 0 0;
}

.m-bottom-10 {
  margin: 0 0 10px 0;
}

.m-bottom-20 {
  margin: 0 0 20px 0;
}

.m-bottom-30 {
  margin: 0 0 30px 0;
}

.m-bottom-40 {
  margin: 0 0 40px 0;
}

.m-y-10 {
  margin: 10px 0;
}

.m-y-20 {
  margin: 20px 0;
}

.m-y-30 {
  margin: 30px 0;
}

.m-y-40 {
  margin: 40px 0;
}

.p-content {
  padding: var(--padding-large);
}

.p-x-content {
  padding: 0 var(--padding-large);
}

.default-error-box {
  color: var(--color-red-heavy);
  font-weight: 500;
  margin: 0 0 16px 0;
  border-radius: 5px;
}

.contract-list-item {
  padding: 0 0 40px 0;
  margin: 0 0 40px 0;
  border-bottom: 1px solid var(--color-progress-border);
}

.contract-list-actions {
  margin: 20px 0 0 0;

  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  & > div {
    margin: 0 0 10px 0;

    @include tablet {
      margin: 0;
      flex: 0 0 calc(50% - 25px);
    }
  }
}

.copy-header {
  margin: 20px 0 16px 0;

  @include tablet {
    margin: 20px 0 24px 0;
  }
}

.bottom-buttons {
  margin: 30px 0 0 0;
}

.truncated {
  @include truncate;
}

.dialog-wrapper.overlay-confirm,
.dialog-wrapper.overlay-edit {
  .overlay {
    // width: calc(100% - 32px);
    // left: 16px;
    margin: 0;
    background-color: var(--color-progress-bar-bg);

    // @include landscape {
    //   left: 25px;
    //   width: calc(100% - 50px);
    // }

    // @include desktop {
    //   width: 974px;
    //   left: 50vw;
    //   margin: 0 0 0 -487px;
    // }
  }
}

// .dialog-wrapper.wide,
// .dialog-wrapper.overlay-confirm-doc,
// .dialog-wrapper.overlay-confirm {
//   .overlay {
//     @include desktop {
//       left: 25px;
//       width: calc(100% - 50px);
//       margin: 0;
//     }

//     @include wide {
//       max-width: 1550px;
//       left: 50vw;
//       margin: 0 0 0 -775px;
//     }
//   }
// }

.error-message {
  line-height: 1.3;
  position: relative;
  margin: 4px 0 0 10px;
  font-size: 1.5rem;
  font-style: italic;
  color: var(--color-text-passive);
  min-height: 20px;

  @include tablet {
    font-size: 1.7rem;
    min-height: 22px;
  }

  color: var(--color-text-error);
}

.loading {
  height: 100vh;
  // background-color: var(--color-progress-bar-bg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-warning-extra-heavy {
  color: var(--color-warning-extra-heavy);
}

.color-warning-heavy {
  color: var(--color-warning-heavy);
}

.color-green-heavy {
  color: var(--color-green-heavy);
}

.color-success-text {
  color: var(--color-success-text);
}

.color-red-heavy {
  color: var(--color-red-heavy);
}

.color-wl-blue {
  color: var(--color-wl-blue);
}

.color-wl-turquoise {
  color: var(--color-wl-turquoise);
}

.color-wl-pink {
  color: var(--color-wl-pink);
}
