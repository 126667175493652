.root {
  & > * {
    padding: 24px 36px;
  }
}

.header {
  border-bottom: 1px solid var(--color-snow-heavy);
}

.header_text {
  display: flex;
  align-items: center;
  gap: 20px;
}

.body {
  & > * {
    margin-bottom: 20px;
  }
}

.columns {
  display: flex;
  gap: 15px;

  & > * {
    flex: 1;
  }
}

.actions {
  display: flex;
  gap: 10px;
  border-top: 1px solid var(--color-snow-heavy);
  background-color: var(--color-snow);

  border-radius: 0 0 15px 15px;
}

.delete_button {
  flex-basis: 150px;
}

.close_button {
  margin-left: auto;
  flex-basis: 150px;
}

.save_button {
  flex-basis: 150px;
}
