@import "../../ui/media.scss";

.filter-view {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .content {
    flex: 1;
    width: 100%;
  }

  .content-error {
    display: flex;
    align-items: center;
  }

  .filter-view-pagination {
    margin-top: auto;
    width: 100%;
    overflow-x: auto;
  }
}
