.root {
  background-color: var(--color-snow);
  border-radius: var(--border-radius-content);
  overflow: hidden;
  margin-bottom: 30px;
}

.header {
  padding: var(--padding-large);
  padding-bottom: calc(var(--padding-large) / 2);
  //   background-color: var(--color-snow-heavy);
}

.subheader {
  padding: calc(var(--padding-large) / 2) var(--padding-large);

  display: grid;
  grid-template-columns: 1.25fr repeat(3, 0.75fr);
  //   background-color: var(--color-snow-heavy);
  gap: 20px;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--color-text-passive);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.row {
  display: grid;
  grid-template-columns: 1.25fr repeat(3, 0.75fr);
  padding: calc(var(--padding-large) / 2) var(--padding-large);
  gap: 20px;

  align-items: center;

  //   color: var(--color-text-passive);
  font-weight: 500;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-snow-heavy);
  }
}

.article {
  display: flex;
  gap: 10px;
  align-items: center;
}
