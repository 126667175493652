.root {
  width: auto;
}

.options {
  position: absolute;
  z-index: 100;
  top: calc(100% + 6px);
  width: 100%;
  max-height: 500px;
  overflow: auto;

  background-color: var(--color-white);
  border: 1px solid var(--color-input-border);
  border-radius: 6px;
  padding: 8px 0;

  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  gap: 16px;

  .indicator {
    flex: 0 0 auto;
  }

  label {
    font-weight: 400;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 10;

    opacity: 0 !important;
    cursor: pointer;
  }

  input:focus ~ .indicator {
    outline: 2px solid black;
    outline-offset: 2px;
  }
}

.selectedOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 6px 0;
  font-weight: 500;

  .selectedValue {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: var(--font-size-small);
    padding: 5px 12px;
    border-radius: 8px;
    border: 1px solid var(--color-link);
    background-color: var(--link-alt);
    max-width: 100%;

    cursor: pointer;

    svg {
      flex-shrink: 0;
    }
  }
}

.disabled {
  color: var(--color-text-secondary);
}
