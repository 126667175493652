@import "../../../../../../ui/media.scss";

.country-pricing-page {
  .pre-set-list {
  }

  .pre-set-row {
    box-shadow: var(--box-shadow-content);
    border: 1px solid var(--color-input-border);
    border-bottom: 0;
    overflow: hidden;
  }

  li:first-child {
    .pre-set-row {
      border-top-left-radius: var(--border-radius-content);
      border-top-right-radius: var(--border-radius-content);
    }
  }

  li:nth-child(even) {
    .pre-set-row {
      background-color: #edf6f8;
    }
  }

  li:last-child {
    .pre-set-row {
      border-bottom-left-radius: var(--border-radius-content);
      border-bottom-right-radius: var(--border-radius-content);
      border: 1px solid var(--color-input-border);
    }
  }
}

.pre-set-row-wrapper {
  &.archived {
    .pre-set-row-name,
    .pre-set-row-description,
    .pre-set-row-buttons,
    .pricing-pulse,
    .pre-set-row-info {
      transition: opacity 300ms ease;
      opacity: 0.5;
    }

    &:hover {
      .pre-set-row-name,
      .pre-set-row-description,
      .pre-set-row-buttons,
      .pricing-pulse,
      .pre-set-row-info {
        opacity: 1;
      }
    }

    .pre-set-row-enable {
      display: block;

      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.pre-set-row-wrapper {
  position: relative;

  .pre-set-row {
    padding: 18px;
    background-color: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 1fr 70px;
    column-gap: 10px;

    @include tablet {
      grid-template-columns: 1fr 1fr 78px 1fr;
      column-gap: 40px;
    }
  }

  .pre-set-row-labels {
    display: flex;
    gap: 10px;
  }

  .pre-set-row-label {
    padding: 4px 12px;
    border-radius: 50px;

    color: white;

    display: flex;
    align-items: center;
    gap: 5px;
  }

  .pre-set-row-pricing-model-blended {
    background: linear-gradient(-45deg, #71c2f9, #305338);
  }

  .pre-set-row-pricing-model-icpp {
    background: linear-gradient(-45deg, #71c2f9, #fc575e);
  }

  .pre-set-row-pricing-model-pack {
    background: linear-gradient(-45deg, #71c2f9, #fdd552);
  }

  .pre-set-contract-type-instore {
    background-color: var(--color-wl-blue);
  }

  .pre-set-contract-type-e-commerce {
    background-color: var(--color-wl-yellow);
  }

  .pre-set-row-name {
    grid-column: 1/3;
    grid-row: 1/2;

    @include tablet {
      grid-column: 1/3;
    }
  }

  .pre-set-row-buttons {
    display: flex;
    gap: 10px;
    grid-column: 3/4;
    grid-row: 1/2;

    @include tablet {
      grid-column: 3/4;
    }
  }

  .pre-set-row-description {
    grid-column: 1/4;
    grid-row: 2/3;
    color: var(--color-text-passive);
    padding: 0;

    @include tablet {
      grid-column: 1/4;
    }
  }

  .pricing-pulse {
    grid-column: 1/4;
    max-height: 50px;

    @include tablet {
      grid-column: 4/5;
      grid-row: 1/4;
      max-height: 80px;
    }

    & > svg {
      display: block;
      height: 100%;
    }
  }

  .pricing-pulse-overlay {
    height: 80px;
    display: flex;
    align-items: center;
    background-color: var(--color-snow);
    padding: 10px;
    line-height: 1.5;
    text-align: center;
  }

  .pre-set-row-info {
    grid-column: 1/4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

    @include tablet {
      grid-column: 1/4;
      grid-row: 3/4;
      padding: 2px 0 0 0;
    }

    // & > div {
    //   flex: 1 0 auto;
    //   max-width: 50%;
    //   display: inline-flex;
    //   align-items: center;
    // }

    & > div:last-child {
      justify-content: flex-end;
    }
  }

  .model-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 6px 0 0;
  }

  &.BLENDED {
    .model-indicator {
      background: linear-gradient(-45deg, #71c2f9, #305338);
    }
  }

  &.ICPP {
    .model-indicator {
      background: linear-gradient(-45deg, #71c2f9, #fc575e);
    }
  }

  &.PACK {
    .model-indicator {
      background: linear-gradient(-45deg, #71c2f9, #fcf457);
    }
  }

  .pre-set-row-enable {
    display: none;
    grid-column: 1/4;
    text-align: center;
    background-color: var(--link-alt);
    margin: 4px -10px 0 -10px;
    padding: 4px 10px 10px 10px;

    @include tablet {
      grid-column: 1/5;
      grid-row: 4/5;
      padding: 5px 10px;
    }

    .button {
      @include tablet {
        max-width: 180px;
      }
    }
  }
  // .pre-set-row-top {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }

  // .pre-set-row-body {
  //   line-height: 1.5;
  // }
}
