.root {
  border-radius: 10px;
  overflow: hidden;
}

.header {
  padding: var(--padding-large);
  padding-bottom: var(--padding-medium);
  background-color: #c5d4f1;
}

.body {
  padding: var(--padding-large);
  background-color: #dae3f5;
}

.edit_row {
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  gap: 50px;

  margin-bottom: 40px;
}

.display_body {
  background-color: #dae3f5;
}

.display_row_header {
  padding: calc(var(--padding-large) / 2) var(--padding-large);
  background-color: #c5d4f1;

  display: grid;
  grid-template-columns: 1fr 1fr;

  font-weight: 500;
}

.display_row {
  padding: calc(var(--padding-medium) / 2) var(--padding-large);

  display: grid;
  grid-template-columns: 1fr 1fr;

  font-weight: 500;

  &:not(:last-of-type) {
    border-bottom: 1px solid #c5d1e9;
  }
}

.add_threshold {
  text-align: center;
}
