@import "../../../ui/media.scss";

$size: 15px;
$border: 10px;
$green: #4bdd57;
$red: #ff4242;
$yellow: #ffda72;
$blue: #42e5ff;

.admin-archive-page {
  padding: 20px 0 100px 0;
  max-width: $desktop-plus;

  h1 {
    position: relative;
    display: inline-block;
  }

  .sleeping {
    font-size: 25px;

    & > span {
      margin: 0 10px 0 0;
      top: 2px;
      position: relative;
    }
  }

  .indicator {
    position: absolute;
    display: block;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin: $border;
    right: -35px;
    top: 4px;

    &.online,
    &.online:before {
      background: $green;
    }

    &.offline,
    &.offline:before {
      background: $red;
    }

    &.invisible,
    &.invisible:before {
      background: $blue;
    }

    &.idle,
    &.idle:before {
      background: $yellow;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -5px;
      top: -5px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      animation: archiving 1.5s infinite ease-in;
    }
  }

  .records {
    max-width: 500px;
    margin: 80px 0 0 0;
  }

  .records-header {
    border-bottom: 1px solid var(--color-pure-black);

    div:last-child {
      text-align: right;
    }
  }

  .record {
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex: 0 0 calc(100% - 100px);
      border-bottom: 1px solid #bdbdbd;
      padding: 4px;
    }
  }

  .missing {
    background-color: var(--color-delete-light);
  }

  .record-status {
    text-align: right;
    flex: 0 0 100px;
    max-width: 100px;
  }
}

@keyframes archiving {
  from {
    transform: scale(0.5);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
