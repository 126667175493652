.wrapper {
  margin-bottom: 10px;
}

.root {
  overflow: hidden;
}

.content {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding: var(--padding-xs);
  gap: 15px;
  cursor: pointer;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;

  .icon {
    width: var(--height-interaction-small);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.label {
  margin-left: auto;
  font-weight: 500;
  margin-right: 20px;
  padding: 2px 8px;
  font-size: var(--font-size-1);
  //   text-transform: uppercase;

  border-radius: 8px;
}

.instore_color {
  background-color: #b4e2ee;
  color: #06313d;
}

.ecommerce_color {
  background-color: #eacfd1;
  color: #601b21;
}

.body {
}

.form {
  overflow: hidden;
  padding: var(--padding-large);
  border-top: 1px solid var(--color-progress-border);
}
