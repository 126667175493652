.root {
  margin-bottom: 10px;
}

.description {
  background-color: var(--color-snow-light);
  border-radius: var(--border-radius-button);
  padding: 15px;
  color: var(--color-text-passive);
  font-weight: 500;
  margin: 10px 0;
}
