.root {
  height: var(--height-interaction);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  padding: 10px 15px;
  background-color: var(--link-alt);

  font-weight: 500;
  color: #005858;
  border-radius: 8px;
}

.button {
  align-items: flex-end;
}

.labelHolder {
  display: flex;
  gap: 10px;
}
