@import "../../ui/media.scss";

.list {
  max-width: 600px;
  margin: 0 auto;

  li {
    position: relative;
    padding: 0 0 5px 34px;
  }
}

.disabled {
  width: 20px;
  height: 20px;
}

.warning {
  font-weight: 500;

  background-color: var(--color-warning-light);
  padding: 25px;
  border-radius: 10px;

  max-width: 600px;
  margin: 0 auto;
}

.success {
  background-color: var(--link-alt);
  padding: 25px;
  border-radius: 10px;
}

.error {
  background-color: var(--color-red-light);
  padding: 25px;
  border-radius: 10px;
}

.icon {
  position: absolute;
  top: 5px;
  left: 0;
}

.flex {
  display: flex;
  align-items: center;

  & > div {
    flex: 0 0 60px;
    min-width: 60px;

    &:last-child {
      flex: 1 0 auto;
      line-height: 1.4;
    }
  }
}

.data_list {
  margin-top: 40px;
  font-weight: 500;

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0 0 20px 0;
  }

  dt,
  dd {
    margin: 0 0 8px 0;

    display: flex;
    align-items: center;
  }

  dt {
    width: 0;
    flex: 0 0 180px;
    font-style: italic;
    color: var(--color-text-passive);
  }

  dd {
    justify-content: flex-end;
    width: 0;
    flex: 1 0 calc(100% - 180px);
    text-align: right;
    @include truncate;

    .beacon {
      display: inline-block;
    }
  }
}
