.root {
  position: relative;
  font-weight: 500;
  min-width: fit-content;
}

.indicator {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--color-link);
}

.label {
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 12px;
  height: var(--height-interaction);
  padding-left: 12px;
  padding-right: 24px;
  border-radius: 8px;

  // border: 1px solid var(--color-input-border);
  background-color: var(--color-content);

  box-shadow: var(--box-shadow-tiny);
}

.label_text {
  white-space: nowrap;
}

.active_label {
  color: var(--color-link);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2em;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-2);

  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: var(--color-snow-heavy);
}

.dropdown_items {
  position: absolute;
  z-index: 50;
  background-color: var(--color-content);
  min-width: 150%;
  border-radius: 8px;
  padding: 5px;
  box-shadow: var(--box-shadow-small);
}

.li {
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.li:not(:last-of-type) {
  margin-bottom: 5px;
}

.li_selected {
  color: var(--color-link);
  background-color: var(--link-alt);
}
