@import "../../ui/media.scss";

.checkbox-checked {
  .checkbox-checked-checkmark {
    stroke-width: 2px;
  }

  .checkbox-checked-box {
    stroke: var(--color-link);
  }
}
.truncated {
  @include truncate;
}
.checkbox {
  background-color: var(--color-bg);
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;

  @include tablet {
    height: 24px;
    width: 24px;
  }

  .checkbox-checked-box {
    stroke: none;
    fill: var(--color-link);
  }

  .checkbox-unchecked {
    fill: none;
    stroke: var(--color-silver);
    stroke-width: 2px;
    height: inherit;
    width: inherit;
  }

  .checkbox-checked-checkmark {
    fill: none;
    stroke: var(--color-bg);
    stroke-width: 2px;
  }

  .checkbox-checked,
  .checkbox-unchecked {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms ease;
    transform-origin: center;
  }

  .checkbox-checked {
    transform: scale(0);
  }

  &.checked {
    .checkbox-checked {
      transform: scale(1);
    }

    .checkbox-unchecked {
      transform: scale(0);
    }
  }
}

.checkbox-buttons {
  &.is-disabled {
    .checkbox-unchecked {
      stroke: var(--color-progress-border);
    }

    .checkbox-checked-box {
      fill: var(--color-progress-border);
    }
  }
}
