.root {
  position: relative;
  font-weight: 500;
  min-width: fit-content;
}

.indicator {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--color-link);
}

.label {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--height-interaction);
  width: var(--height-interaction);

  box-shadow: var(--box-shadow-tiny);

  border-radius: 8px;

  background-color: var(--color-content);
}

.label_text {
  white-space: nowrap;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-2);

  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: var(--color-snow-heavy);
}

.dropdown_items {
  position: absolute;
  z-index: 50;
  background-color: var(--color-content);
  min-width: 500px;
  right: 0;
  border-radius: 12px;
  padding: 25px;
  box-shadow: var(--box-shadow-small);
}

.li {
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.li:not(:last-of-type) {
  margin-bottom: 5px;
}

.li_selected {
  color: var(--color-link);
  background-color: var(--link-alt);
}
