.tabs {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 6px;
  // border-radius: 200px;
  overflow: visible;
  // background-color: var(--color-progress-bar-bg);
  border: 1px solid var(--color-progress-border);

  .active-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: var(--link-alt);
    border: 1px solid var(--color-button);
    margin: -1px;
    z-index: -1;
    opacity: 1;
    border-radius: 6px - 2px;
    // border-radius: 200px;
  }

  li {
    z-index: 2;
    position: relative;
    flex: 1;
    text-align: center;

    button {
      position: relative;
      padding: 8px 12px;
      display: block;
      font-weight: 600;
      width: 100%;
      transition: color 0.2s ease-in-out;
      color: var(--color-text-passive);

      &.active {
        color: var(--color-link);
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;

          &:before {
            content: none;
          }
        }
      }

      &:hover {
        color: var(--color-text);

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          z-index: -2;
        }
      }
    }
  }
}
