@import "../../ui/media.scss";

.backoffice-completed {
  padding: 50px 0 200px 0;
  min-height: 100vh;
  max-width: 748px;
  margin: 0 auto;

  .logo {
    max-width: 225px;
    height: 70px;
  }

  .fieldset-header {
    background-color: white;
  }

  .center-button {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  .hidden {
    visibility: hidden;
    pointer-events: none;
  }

  .button {
    width: 100%;
    margin: 10px 0 40px 0;
  }

  .add-merch-id {
    margin: 40px 0 0 0;
  }

  .fieldset .input {
    margin: 40px 0 0 0;
    display: block;
  }

  .has-cashless {
    // .button {
    //   margin: 0;
    // }

    // .input {
    //   margin: 30px 0 0 0;
    // }

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .input {
        width: calc(100% - 140px);
        flex: 0 0 calc(100% - 140px);
        margin: 0 0 30px 0;
      }

      .button {
        width: 120px;
        flex: 0 0 120px;
        margin: 0 0 30px 0;
      }
    }
  }

  .download-button {
    margin: 50px 0 0 0;
  }

  .error-box {
    padding: 0 0 20px 0;
  }
}
