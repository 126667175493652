.searchable {
  position: relative;

  &-list {
    border-radius: 0 0 6px 6px;
    position: absolute;
    width: 100%;
    top: calc(28px + 40px);
    left: 0;
    right: 0;
    transition: opacity 300ms ease;
    opacity: 1;
    background-color: white;
    box-shadow: 0 6px 14px 6px rgba(0, 0, 0, 0.1);
    overflow: auto;
    overflow-x: hidden;
    background-image: linear-gradient(-250deg, #273444 30%, #19424e 50%, #3c4858);
    border: 1px solid #273444;
    z-index: 10;

    li {
      border-bottom: 1px solid #273444;

      &:last-child {
        border-bottom: 0;
      }
    }

    button {
      width: 100%;
      padding: 10px 10px;
      color: var(--color-white);
      text-align: left;

      &:hover,
      &:active,
      &:focus {
        background: linear-gradient(90deg, #0b5f89 30%, #2cb098);
      }
    }
  }

  &--top {
    .searchable-list {
      bottom: var(--height-interaction);
      border-radius: 6px 6px 0 0;
      box-shadow: 0 -6px 14px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
