.body {
  max-width: 900px;
  margin: 0 auto;
}

.actions_row {
  display: flex;
  // align-items: center;
  gap: 20px;
}

.search_container {
  flex-basis: 350px;
}

.add_container,
.upload_container {
  flex-basis: 200px;
  margin-top: 30px;
}

.add_container {
  margin-left: auto;
}

.accepted {
}

.forbidden {
  td {
    background-color: var(--color-red-light) !important;
  }
}

.restricted {
  td {
    background-color: var(--color-warning-light) !important;
  }
}

.incapable {
  td {
    background-color: rgb(255, 225, 185) !important;
  }
}

.risk_level {
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
}

.green {
  svg {
    fill: var(--link);
  }
}

.red {
  svg {
    fill: var(--color-red-heavy);
  }
}

.warning {
  svg {
    fill: var(--color-warning-heavy);
  }
}

.loading {
  background-color: var(--color-white);
  min-height: 90vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
