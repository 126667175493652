.root {
  background-color: white;
  border: 1px solid var(--color-silver);
  padding: var(--padding-medium);
  border-radius: 12px;
}

.p {
  margin-top: 18px;
  margin-bottom: 24px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: var(--color-link);

  // mail icon
  svg {
    font-size: 1.3em;
  }
}

.statusBoxes {
  margin-top: 18px;
  margin-bottom: 24px;
}
