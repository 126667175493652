@import "../../ui/media.scss";

.promotion-months {
  .offer-start-wrapper {
    margin: 0 0 40px 0;

    @include landscape {
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    & > div {
      @include landscape {
        flex: 1 0 auto;
        max-width: 50%;
      }
    }
  }

  div.offer-start-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;
    text-transform: capitalize;
    font-style: italic;
  }
}
