@import "../../../../ui/media.scss";

.bank-statement-review {
  h4 {
    margin-bottom: var(--padding-small);
  }

  .review-columns {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;

      & > :nth-child(2) {
        flex: 0 0 320px;
      }
      & > :first-child {
        flex: 1;
      }
    }
  }
}
