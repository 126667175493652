.root {
  border-radius: var(--border-radius-content);
  gap: 10px;
  background-color: var(--color-snow);
}

.heading {
  padding: var(--padding-large);
}

.terminal_summary {
  border-top: 1px solid var(--color-snow-heavy);
  display: flex;
  padding: var(--padding-large);
  // border: 1px solid var(--color-snow-heavy);
  gap: 20px;
  overflow: hidden;
}

.content {
  flex: 1;
}

.first_row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.amount {
  font-size: 4rem;
  line-height: 1;
  font-weight: 700;
  text-align: right;
}

.second_row {
  padding-left: 10px;
  .fee {
    padding-top: 10px;
    text-align: right;
  }
}

.third_row {
  padding-top: 10px;
}

.button {
  display: flex;
  gap: 8px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

// .button {
//   svg {
//     fill: var(--color-link) !important  ;
//   }
// }
