

.document-upload {
  .document-upload-status {
    background-color: var(--color-snow);

    border-radius: 10px;

    // padding: 0 0 5px 55px;
    padding: 16px;
    position: relative;

    .beacon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}


.document-upload-view {
  padding: 0 0 0 55px;
  margin: 0 0 16px 0;
  position: relative;

  .beacon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.root {
  padding: 18px;
  border-radius: 10px;


  border: 1px dashed var(--color-silver);
}

.uploaded {
  background-color: var(--color-success-light);
  border: 1px solid var(--color-success);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0;
}

.description {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    width: 100%; 
    line-height: 1.5; 
    color: var(--color-text-passive); 
}


.small{
    display: flex;            
  align-items: center;      
  justify-content: center;  
  background: none;         
  border: none;             
  cursor: pointer;          
  padding: 0; 
}

.notification {
    position: fixed;
    top: 20px; 
    left: 50%;
    transform: translateX(-50%); 
    z-index: 10; 
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    animation: fadeInOut 3s forwards;
  }
  
  .success {
    background-color: #d4edda; 
    color: #155724; 
  }
  
  .error {
    background-color: #f8d7da; 
    color: #721c24; 
  }
  
  .link_container {
    text-align: right; 
    margin-top: 20px; 
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }