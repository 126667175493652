@import "../../../../../../ui/media.scss";

.terminal-rows-wrapper {
  width: calc(100% + 20px);
  margin: 14px -10px 10px -10px;
  overflow: auto;

  @include landscape {
    width: calc(100% + 32px);
    margin: 14px -16px;
  }

  @include desktop {
    overflow: auto;
  }
}

.terminals-additional-cost {
  margin: 0 0 40px 0;
  line-height: 1.5;
}

.store-terminals {
  margin: 25px 0 40px 0;
}

.add-terminal-buttons {
  display: flex;
  margin: 10px 0 0 0;
  align-items: center;

  .terminal-soft,
  .terminal-move,
  .terminal-desk {
    flex: 0 0 auto;
    display: none;
    align-items: center;
  }

  span {
    margin: 0 0 0 10px;
  }

  .terminal-move {
    margin: 0 30px 0 0;
  }

  &.MOVE_BUNDLE {
    .terminal-move {
      display: flex;
    }
  }

  &.DESK_BUNDLE {
    .terminal-desk {
      display: flex;
    }
  }

  &.SOFTPOS_BUNDLE {
    .terminal-soft {
      display: flex;
    }
  }
}

.terminal-rows {
  // min-width: 500px;

  ul {
    display: flex;
    flex-wrap: nowrap;
  }

  .terminal-closure {
    .input-label,
    .input-status {
      display: none;
    }
  }

  .terminal-row {
    ul {
      border-bottom: 1px solid var(--color-smoke-light);
      background-color: var(--color-snow-light);
    }
  }

  .terminal-row-header {
    .terminal-name,
    .terminal-reservation,
    .terminal-tips,
    .terminal-closure,
    .terminal-remove {
      background-color: var(--color-smoke-light);
      font-weight: 500;
    }
  }

  .terminal-name,
  .terminal-reservation,
  .terminal-tips,
  .terminal-closure,
  .terminal-remove {
    padding: 4px 10px;
    align-self: center;

    @include landscape {
      padding: 4px 16px;
    }
  }

  .terminal-missing {
    padding: 4px 10px;

    @include landscape {
      padding: 10px 16px;
    }
  }

  .terminal-closure {
    .input {
      margin: 0;
    }

    input {
      height: var(--height-interaction-mini);
      padding: 0 9px;
    }

    .input-messages {
      display: none;
    }
  }

  .terminal-remove {
    flex: 0 0 20%;
    max-width: 20%;
    text-align: right;
  }

  .terminal-closure,
  .terminal-name {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .terminal-name {
    flex: 1 0 auto;
    max-width: 80%;
  }

  .terminal-tips {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .terminal-reservation {
    flex: 0 0 32%;
    max-width: 32%;
  }
}
