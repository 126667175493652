.pricing-pulse {
  fill: none;
  position: relative;

  svg {
    width: 100%;
  }

  .pricing-pulse-label {
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 1;
    background-color: var(--color-text);
    padding: 3px 4px;
    font-weight: 600;
    line-height: 1;
    color: var(--color-white);
    border-radius: 4px;
  }
}
