.root {
  margin-top: 10px;
  font-weight: 500;
}

.image {
  background-color: var(--color-bg);
  border-radius: var(--border-radius-content);
  padding: 10px;
  border: 1px solid var(--color-input-border);
  width: 100%;
}
