.fee-brand-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 5px;

  .fee-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
    width: 52px;
    border-radius: 5px;
    background: var(--color-white);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  }

  .fee-logo-grey {
    background: var(--color-snow);
    box-shadow: none;
  }

  .fee-type-wrapper {
    display: block;
    font-size: 0.6em;
    text-align: center;
    width: 100%;
  }

  .fee-type {
    background-color: var(--color-black);
    color: var(--color-white);
    border-radius: 4px;
    padding: 2px;
    font-weight: 600;
    width: 100%;
  }

  .fee-tariff-class {
    background-color: var(--color-wl-blue);
    color: var(--color-white);
    border-radius: 4px;
    padding: 2px;
    font-weight: 600;
    width: 100%;
  }
}
