.root {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;
  font-size: 24px;

  border-radius: 5px;
  background-color: transparent;

  &:hover {
    background-color: var(--color-snow);
    color: var(--color-link);
  }
}

.contained {
  // background-color: var(--color-snow);

  &:hover {
    background-color: var(--color-snow);
    color: var(--color-text);
    // border: 1px solid var(--color-link);
  }
}

.rounded {
  border-radius: 50%;
}

.small {
  font-size: 16px;
}

.large {
  font-size: 32px;
}
