@import "../../ui/media.scss";

.container {
  max-width: $tablet;
  margin: 0 auto;
}

.root {
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-red-heavy);
  background-color: var(--color-red-light);
  padding: var(--padding-large);
  color: var(--color-red-extra-heavy);

  .p {
    margin-top: 18px;
    margin-bottom: 24px;
    font-weight: 500;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;

    // trash icon
    svg {
      font-size: 1.3em;
    }
  }
}
