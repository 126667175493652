@import "../../ui/media.scss";

.header-with-copy {
  min-height: var(--height-interaction);

  .header-with-copy-title {
    margin: 0;
    font-weight: 500;
  }

  .copy-from {
    margin: 6px 0 0 0;

    @include tablet {
      margin: 0;
    }
  }

  @include tablet {
    min-height: var(--height-interaction);
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }
}
