@import "../../../ui/media.scss";

.logins {
  .tablet-columns {
    @include tablet {
      & > .column,
      & > div {
        flex: 0 0 calc(33.332% - 20px);
      }
    }
  }

  .portal-form-wrapper {
    background-color: var(--color-wl-yellow);
    padding: 30px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
