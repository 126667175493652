.root {
  padding: var(--padding-large);
  border-radius: var(--border-radius-content);
  background-color: var(--color-snow);
  margin-bottom: 30px;
}

.checkbox {
  display: flex;
  align-items: center;

  & > * {
    width: 100%;
  }
}

.promotion_months_toggle {
  font-weight: 500;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--color-snow-heavy);
}

.first_invoice {
  display: flex;
  align-items: center;
}

.months_toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
