@import "../../ui/media.scss";

.filter-dates {
  @include tablet {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .input {
    display: block;
  }

  .input,
  .link,
  .button {
    margin: 10px 0 0 0;

    @include tablet {
      margin: 0 20px 20px 0;
    }
  }

  .button {
    margin: 10px 10px 0 0;

    @include tablet {
      margin: 0 20px 20px 0;
    }
  }
}

.analytics-filter {
  .teams-dropdown {
    min-width: 200px;
  }

  .excel-download {
    display: none;

    .icon {
      display: none;
    }
  }
}

.admin-dashboard {
  .excel-download {
    display: inline-flex;
  }
}
