@import "../../../ui/media.scss";

a.as-button,
.button {
  font-weight: 500;
  height: var(--height-interaction);
  padding: 0 16px;
  transition: background-color 300ms ease, box-shadow 300ms ease, border-color 300ms ease;
  background-color: var(--color-button);
  // box-shadow: var(--color-button-shadow);
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-button);
  cursor: pointer;
  white-space: nowrap;

  &.align-input-label {
    margin: 28px 0 0 0;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  &.action {
    height: var(--height-interaction);
    width: var(--height-interaction);
    border-radius: 50%;
    padding: 0;

    @include tablet {
      height: var(--height-interaction);
      width: var(--height-interaction);
    }

    .icon {
      margin: 0;
    }
  }

  &.mini {
    height: var(--height-interaction-mini);

    @include tablet {
      height: var(--height-interaction-mini);
    }

    &.action {
      width: var(--height-interaction-mini);

      .icon {
        width: 12px;
        height: 12px;

        @include tablet {
          width: 16px;
          height: 16px;
        }
      }

      @include tablet {
        width: var(--height-interaction-mini);
      }
    }
  }

  &.small {
    height: var(--height-interaction-small);

    @include tablet {
      height: var(--height-interaction-small);
    }

    &.action {
      width: var(--height-interaction-small);

      .icon {
        width: 14px;
        height: 14px;

        @include tablet {
          width: 18px;
          height: 18px;
        }
      }

      @include tablet {
        width: var(--height-interaction-small);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-button-hovered);
    box-shadow: var(--color-button-shadow-transparent);
    color: var(--color-white);
    text-decoration: none;
  }

  & + .button {
    // margin: 0 0 0 10px;
  }

  & + .button.block,
  & + .as-button.block {
    // margin: 10px 0 0 0;
  }

  &.block {
    display: flex;
    width: 100%;

    & + .button {
      margin: 10px 0 0 0;
    }
  }

  .icon {
    margin: 0 0 0 6px;
  }

  &.danger-button {
    background-color: var(--color-red-heavy);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color-red);
    }
  }

  &.success-button {
    background-color: var(--color-green);
  }

  &.has-error,
  &.was-successful,
  &.is-pending,
  &.is-disabled {
    color: var(--color-white);
    box-shadow: none;
  }

  &.has-error {
    background-color: var(--color-red-heavy);
  }

  &.was-successful {
    background-color: var(--color-green);
  }

  &.is-pending {
    background-color: var(--color-silver);
    cursor: wait;
  }

  &.is-disabled {
    background-color: var(--color-silver);
    cursor: not-allowed;
  }

  &.ghost {
    background-color: var(--color-white);
    color: var(--color-button-ghost);
    border: 2px solid var(--color-button-ghost);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--link-alt);
    }

    &.has-error {
      color: var(--color-red-heavy);
      border: 2px solid var(--color-red-heavy);

      .icon {
        fill: var(--color-red-heavy);
      }
    }

    &.was-successful {
      color: var(--color-green);
      border: 2px solid var(--color-green);

      .icon {
        fill: var(--color-green);
      }
    }

    &.is-pending,
    &.is-disabled {
      color: var(--color-silver);
      border: 2px solid var(--color-silver);

      .icon {
        fill: var(--color-silver);
        & > span {
          background-color: var(--color-silver);
        }
      }
    }
  }

  &.ghost {
    .close {
      line {
        stroke: var(--color-link);
      }
    }
  }
}

// ----------- BUTTON VARIANTS ----------->

.button-variant-primary {
  border-radius: 8px;
  color: var(--color-variant-primary);
  background-color: var(--bg_color-variant-primary);

  .icon {
    fill: var(--color-variant-primary);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--bg_color_hover-variant-primary);
  }
}

.button-variant-CTA {
  color: var(--color-variant-primary);
  background-color: var(--bg_color-variant-primary);

  border-radius: 50px;
  height: 48px;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--bg_color_hover-variant-primary);
  }
}

.button-variant-text {
  color: var(--color-variant-text);
  background-color: transparent;

  .icon {
    fill: var(--color-variant-text);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--color-variant-text);
    background-color: var(--bg_color_hover-variant-text);
  }

  &.is-disabled {
    background-color: transparent;
    color: var(--color-text-passive);

    .icon-disabled {
      display: none;
    }
  }
}

.button-variant-outlined {
  color: var(--color-variant-text);
  background-color: transparent;
  border: 1px solid var(--color-variant-text);

  &:hover,
  &:active,
  &:focus {
    color: var(--color-variant-text);
    background-color: var(--bg_color_hover-variant-text);
  }
}

// ----------- BUTTON COLOR THEMES ----------->

.button-color-primary {
  --color-variant-primary: var(--color-white);
  --color-variant-text: var(--color-link);

  --bg_color-variant-text: transparent;
  --bg_color-variant-primary: var(--color-link);

  --bg_color_hover-variant-text: var(--link-alt);
  --bg_color_hover-variant-primary: var(--link-hovered);
}

.button-color-secondary {
  --color-variant-primary: var(--color-text);
  --color-variant-text: var(--color-text);

  --bg_color-variant-text: transparent;
  --bg_color-variant-primary: transparent;

  --bg_color_hover-variant-text: rgba(0, 0, 0, 0.05);
  --bg_color_hover-variant-primary: rgba(0, 0, 0, 0.05);
}

.button-color-danger {
  --color-variant-primary: var(--color-white);
  --color-variant-text: var(--color-red-heavy);

  --bg_color-variant-text: transparent;
  --bg_color-variant-primary: var(--color-red-heavy);

  --bg_color_hover-variant-text: var(--color-red-light);
  --bg_color_hover-variant-primary: var(--color-red);
}

.button-color-mock {
  --color-variant-primary: var(--color-white);
  --color-variant-text: #005a8c;

  --bg_color-variant-text: transparent;
  --bg_color-variant-primary: #005a8c;

  --bg_color_hover-variant-text: #e9e0fa;
  --bg_color_hover-variant-primary: #2681b1;
}
