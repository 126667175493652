@import "../../ui/media.scss";

.image {
  background-color: transparent;
  position: relative;

  & > img {
    opacity: 0;
    transition: opacity 600ms ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.loaded {
    & > img {
      opacity: 1;
    }
  }
}

.new-image {
  background-color: transparent;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 90px;
  width: 90px;

  @include tablet {
    height: 125px;
    width: 125px;
  }

  .new-image-background {
    height: 65%;
    width: 65%;
    border-radius: 50%;
    background-color: var(--color-link);

    transition: opacity, scale, background-color 600ms ease;
  }

  .image-not-selected {
    background-color: var(--color-snow-heavy);
  }

  & > img {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 600ms ease;
    object-fit: contain;
  }

  &.loaded {
    & > img {
      opacity: 1;
    }
  }
}

.new-image-small-size {
  height: 60px;
  width: 60px;

  @include tablet {
    height: 70px;
    width: 70px;
  }
}
