@import "../../ui/media.scss";

.vat-input {
  display: flex;

  // justify-content: space-between;

  .input {
    flex: 1;
    padding: 0 10px 0 0;
  }

  .vat-input-validation {
    flex: 0 0 110px;
    text-align: right;
    margin-top: 30px;

    .button {
      width: 110px;
    }
  }
}
