@import "../../ui/media.scss";

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  display: grid;
  grid-template-columns: 1fr;

  @include desktop-mid {
    grid-template-columns: 750px 1fr;
  }
}

.timeline {
  box-shadow: var(--box-shadow-content);
  background-color: var(--color-bg);
  padding: var(--padding-large);
  border-radius: 12px;
}

.actions {
  flex: 1;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.title {
  margin-bottom: 30px;
}
