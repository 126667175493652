.mcc-additional-info {
  padding: 5px 10px;
  font-size: var(--font-size-2);
  font-weight: 500;

  .status-box {
    .icon {
      height: var(--height-interaction-tiny);
      width: var(--height-interaction-tiny);
    }
  }

  .mcc-info-box {
    display: flex;
    align-items: center;
    margin-top: 10px;

    border-radius: 8px;
    line-break: auto;
  }

  .mcc-li-type:not(:last-of-type)::after {
    content: ", ";
  }
}

.mcc-input {
  .input.input-text {
    margin: 0 0 5px 0;
  }

  .input-hint {
    display: none;
  }

  .input-messages {
    min-height: 0;
  }
}
