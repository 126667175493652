.feature-toggle-page {
  padding: 100px 0;

  .toggle {
    text-align: right;
  }

  .toggle-label-wrapper {
    display: inline-flex;
  }

  .fieldset-header {
    background-color: #fff;
  }
}
