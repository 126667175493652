.root {
  padding: var(--padding-large);
  background-color: var(--color-snow);
  border-radius: var(--border-radius-content);
}

.hr {
  border-color: var(--color-snow-heavy);
  margin: 30px 0;
}
