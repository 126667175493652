.header {
  display: grid;
}

.table {
  overflow: hidden;
  border-radius: 10px;
  background-color: var(--link-alt);
}

.table_header {
  padding: var(--padding-large);
  padding-bottom: var(--padding-medium);
  background-color: rgb(203 229 225);
}

.add_threshold {
  margin: 0 auto;
}

.cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
