.pending-overlay {
  transition: opacity 300ms ease;
  z-index: 200;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    background-color: var(--color-white);
    position: absolute;
    opacity: 0.85;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .text {
    margin-top: 20px;
    color: var(--color-link);
  }
}
