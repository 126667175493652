.uploaded_file {
  padding: 12px 16px;
  border-radius: 12px;
  background-color: var(--color-snow);

  display: flex;
  align-items: center;
  gap: 16px;
}

.upload_file_pending {
  opacity: 0.75;
}

.icon {
  display: flex;
  align-items: center;
  padding: 10px;

  background-color: var(--color-snow-heavy);
  border-radius: 8px;

  svg {
    font-size: 1.2em;
  }
}

.body {
  flex: 1;
}

.titleRow {
  display: flex;
  justify-content: space-between;
}

.delete {
  margin-left: auto;
}

.descriptions {
  font-size: var(--font-size-2);
  line-height: var(--line-height-s);
  color: var(--color-text-passive);
  font-weight: 500;
  display: flex;

  li:not(:first-of-type)::before {
    content: "•";
    padding: 6px;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.typeLabel {
  font-size: var(--font-size-1);
  font-weight: 500;
  height: fit-content;

  display: flex;
  align-items: center;
  padding: 3px 12px;
  border-radius: 20px;
  background-color: var(--color-wl-blue);
  color: white;
}
