.buttonsGrid {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  column-gap: 20px;
  row-gap: 10px;
}

.button {
  flex: 1 1 200px;
}
