@import "../../../../ui/media.scss";

.confirm-doc {
  max-width: $desktop;

  & > strong {
    display: block;
    margin: 0 0 12px 0;
  }

  .confirm-button {
    white-space: nowrap;
  }

  .status-boxes {
    min-height: 0;
  }

  .confirm-doc-statuses {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 16px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease;
    font-weight: 500;
    background: linear-gradient(rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.4));
  }

  &.show-status {
    .confirm-doc-statuses {
      opacity: 1;
    }
  }

  dl {
    display: flex;
    max-width: $landscape;
    flex-wrap: wrap;
  }

  dt,
  dd {
    margin: 0;
  }

  dt {
    width: 0;
    flex: 0 0 140px;
    font-style: italic;
  }

  dd {
    width: 0;
    flex: 1 0 calc(100% - 140px);
    @include truncate;
  }

  .document-description {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .confirm-doc-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;

    .confirm-button {
      flex: 1;
    }
  }
}
