@import "../../../ui/media.scss";

.bank {
  .account-owner-toggle {
    margin: 0 0 30px 0;

    b {
      margin: 0 0 8px 0;
      display: block;
      font-weight: 600;
    }
  }
}

.bank-details {
  .info-text {
    display: none;
    margin: 20px 0 0 0;

    @include tablet {
      display: block;
      visibility: hidden;
    }

    &.show {
      display: block;

      @include tablet {
        visibility: visible;
      }
    }
  }

  .lookup-button {
    padding: 0 0 20px 0;
  }
}

.lookup-result {
  position: relative;

  .successful-lookup,
  .manuel-input {
    opacity: 0;
    transition: opacity 300ms ease;
    pointer-events: none;
  }

  .successful-lookup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.was-successful {
    .successful-lookup {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.has-error {
    .manuel-input {
      opacity: 1;
      pointer-events: all;
    }
  }
}
