@import "../../../../../../../ui/media.scss";

.content {
  background-color: var(--link-alt);
  overflow: hidden;
  border-radius: var(--border-radius-content);
}

.heading {
  padding: 25px;
  border-bottom: 1px solid var(--link-alt-divider);
  background-color: rgb(203 229 225);
}

.header {
  @include landscape {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.description {
  color: var(--color-text-passive);
}

.inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  padding: var(--padding-large);
}
