.root {
  display: flex;
  padding: var(--padding-large);
  // border-radius: var(--border-radius-content);
  background-color: var(--color-snow);

  border-top: 1px solid var(--color-snow-heavy);
  gap: 20px;

  overflow: hidden;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terminalTitle {
  font-weight: 600;

  display: inline-block;
  // text-transform: uppercase;
}

.content {
  flex: 1;
  flex-direction: column;
  display: flex;
  // gap: 6px;

  .firstRow {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
  }

  .secondRow {
    padding-left: 10px;
    .fee {
      padding-top: 10px;
      text-align: right;
    }
  }

  .amount {
    font-size: 4rem;
    line-height: 1;
    font-weight: 700;
    text-align: right;
  }
}

.selected_price_error {
  color: var(--color-red-heavy);
  font-size: var(--font-size-2);
  line-height: 1.3;
  font-style: italic;

  border-radius: 5px;
  font-weight: 500;
  margin-top: 10px;
}

.available_prices_error {
  padding: 5px 10px;
  color: var(--color-red-heavy);
  font-size: var(--font-size-2);
  font-style: italic;

  border-radius: 5px;
  font-weight: 500;
}
