.sidebar_menu_items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu_item {
  display: flex;
  align-items: center;

  text-decoration: none;
  color: var(--color-text);
  gap: 15px;
  padding: 10px;

  width: 100%;
  border-radius: var(--border-radius-button);

  transition: background-color ease-in 200ms;
}

.menu_item_active {
  color: var(--color-link);
  background-color: var(--link-alt);

  &:hover,
  &:focus {
    background-color: var(--link-alt);
  }
}

.menu_item_disabled {
  color: var(--color-text-passive);
  background-color: transparent;
  cursor: default;
  pointer-events: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--color-text-passive);
  }
}

.back {
  display: flex;
  align-items: center;
  gap: 8px;
}
