@import "../../../ui/media.scss";

.root {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  gap: 10px;
}

.wrapper_button {
  flex: 1 0 auto;
  max-width: calc(100% - 55px);
  text-align: left;
  color: var(--color-text);

  &:active,
  &:focus,
  &:hover {
    .associate_row {
      border-color: var(--color-black);
    }
  }

  @include tablet {
    flex: 1 0 auto;
    max-width: calc(100% - 80px);
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.associate_row {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 10px;
  border-radius: 50px;
  background-color: var(--color-white);
  border: 1px solid var(--color-progress-border);
  position: relative;

  font-weight: 500;
}

.associate_info {
  flex: 1 0 0;
  max-width: calc(100% - 80px);

  @include truncate;
}

.icon {
  flex: 0 0 var(--height-interaction);
  text-align: center;
}
