@import "../../../../ui/media.scss";

.root {
  display: flex;
  flex-direction: column;

  font-weight: 500;
  padding: var(--padding-large);
  gap: 20px;

  background-color: var(--color-snow);
  border-radius: var(--border-radius-content);
  margin-bottom: 30px;
}

.invoice {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.toggle {
  display: flex;
  align-items: center;
}

.month {
  text-align: center;
  flex: 1;
}
