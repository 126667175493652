@import "../../../../../../ui/media.scss";

.summary-device {
  min-width: 200px;

  display: flex;

  // & > div {
  //   flex: 0 0 50px;

  //   &:last-child {
  //     flex: 0 0 calc(100% - 50px);
  //     padding: 0 0 0 20px;
  //   }
  // }

  .summary-device-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .summary-device-name {
    font-weight: 600;
    line-height: normal;
  }

  .summary-device-count {
    font-size: 4rem;
    line-height: 1;
    font-weight: 700;
    margin: -4px 0 5px 0;
  }
}
