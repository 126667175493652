.root {
  font-weight: 500;
}

.header {
  background-color: rgb(203 229 225);
  border-bottom: 1px solid var(--link-alt-divider);
  // color: var(--color-text-passive);
}

.cashless_header {
  font-style: italic;

  padding: calc(var(--padding-large) / 2) var(--padding-large);
}

.labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: var(--font-size-2);
  font-weight: 600;

  & > * {
    padding: calc(var(--padding-large) / 2) var(--padding-large);
  }
}

.step {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  // color: var(--color-text-passive);
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--link-alt-divider);
  }
}

.fees,
.threshold {
  padding: calc(var(--padding-large) / 2) var(--padding-large);
}

.chip {
  font-size: var(--font-size-1);
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 8px;
  border-radius: 30px;
  background-color: #41b4d2;
  font-weight: 600;
  color: var(--color-blue-extra-heavy);
}
