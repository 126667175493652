@import "../../../ui/media.scss";

.historical-contracts-graph {
  position: relative;
  height: 0;
  padding: 0 0 40% 0;
  margin: 0 20px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: visible;
  }

  .SOFTPOS_BUNDLE {
    fill: green;
    fill-opacity: 0.4;
    stroke: green;
  }

  .DESK_BUNDLE {
    fill: blue;
    fill-opacity: 0.4;
    stroke: blue;
  }

  .MOVE_BUNDLE {
    fill: red;
    fill-opacity: 0.4;
    stroke: red;
  }
}

.historical-contracts-wrapper {
  .filter-button {
    display: block;
    margin: 0 0 10px 0;
    width: 100%;

    @include tablet {
      display: inline-flex;
      width: 130px;
      margin: 0 20px 0 0;
    }
  }

  .historical-contracts-legends {
    margin: 20px 0 30px 0;

    @include landscape {
      display: flex;
    }

    .historical-contracts-legend {
      margin: 0 20px 10px 0;
      display: flex;
    }

    .SOFTPOS_BUNDLE,
    .MOVE_BUNDLE,
    .DESK_BUNDLE {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 6px 0 0;
    }

    .SOFTPOS_BUNDLE {
      background-color: rgba(0, 128, 0, 0.4);
      border: 1px solid green;
    }

    .DESK_BUNDLE {
      background-color: rgba(0, 0, 255, 0.4);
      border: 1px solid blue;
    }

    .MOVE_BUNDLE {
      background-color: rgba(255, 0, 0, 0.4);
      border: 1px solid red;
    }
  }

  .x-ticks {
    text-anchor: middle;
    font-weight: 500;
  }

  .no-of-contracts {
    margin: 0 0 20px 0;
    transition: opacity 500ms ease;
    text-align: right;
    opacity: 1;
  }

  &.filtered-name-date {
    .no-of-contracts {
      opacity: 0;
    }
  }

  .y-ticks {
    rect {
      fill: var(--color-text);
    }

    text {
      text-anchor: middle;
      fill: var(--color-white);
    }
  }
}
