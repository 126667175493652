@import "../../../../../../ui/media.scss";

.root {
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  background-color: var(--color-snow);
  border-radius: 10px;
  // border: 1px solid var(--color-snow-heavy);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.store-info {
  padding: 20px var(--padding-large);
  margin-bottom: 20px;

  // border-bottom: 1px solid var(--color-snow-heavy);
  border-top: 1px solid var(--color-snow-heavy);
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 20px;
}

.store_data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.add {
  flex: 1;

  text-align: right;
}

.device-toggle-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 var(--padding-large) 20px var(--padding-large);
}

.device-toggle {
  display: flex;
  gap: 150px;

  color: var(--color-text-passive);

  margin: 4px 0;
}

.terminal-amount {
  display: none;

  @include desktop {
    display: flex;
    flex: 1;
    align-items: center;
  }
}

.terminal-type-text {
  max-width: 160px;

  @include desktop {
    min-width: 250px;
  }
}

.terminal-add {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-add-terminal {
  display: flex;
  flex: 1;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  @include desktop {
    display: none;
  }
}

.desktop-add-terminal {
  display: none;
  text-transform: capitalize;

  @include desktop {
    display: block;
  }
}

.selected {
  color: var(--color-text);
  font-weight: 500;
}
