@import "../../ui/media.scss";

.table-wrapper {
  overflow-x: scroll;
  // border: 1px solid var(--color-slate);
  box-shadow: var(--box-shadow-content);

  @include desktop {
    overflow-x: auto;
  }

  table {
    min-width: 100%;
    background-color: var(--color-content);
    border-radius: 10px;
    // overflow: hidden;
  }

  thead {
    th {
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      background-color: var(--color-slate);

      color: var(--color-white);
    }

    button {
      color: var(--color-white);
      font-weight: 500;
      display: inline-block;
      width: 100%;
      text-align: left;
      position: relative;
    }
  }

  .ascending:after,
  .descending:after {
    position: absolute;
    content: " ";
    border: 6px solid transparent;
    margin: 0 0 0 8px;
  }

  .ascending:after {
    top: 10px;
    border-top-color: var(--color-white);
  }

  .descending:after {
    top: 2px;
    border-bottom-color: var(--color-white);
  }

  .ascending,
  .descending {
    padding-right: 10px;
  }

  tr:nth-child(even) {
    td {
      // background-color: #f7f7f7;
    }
  }

  .truncate {
    @include truncate;
  }

  .beacon {
    display: inline-block;
  }

  th,
  td {
    text-align: left;
    max-width: 320px;

    &.text-right {
      text-align: right;
    }

    &.text-center {
      text-align: center;
    }

    &.vertical-center {
      vertical-align: middle;
    }
  }

  th {
    padding: 15px 15px;
    min-height: 45px;
    // line-height: 40px;
  }

  th:first-of-type {
    border-top-left-radius: 10px;
  }

  th:last-of-type {
    // border: 1px solid black;
    border-top-right-radius: 10px;
  }

  td {
    padding: 8px 15px;
    // border-bottom: 1px solid var(--color-snow-heavy);

    &:first-child {
      border-left: 1px solid var(--color-input-border);
    }

    &:last-child {
      border-right: 1px solid var(--color-input-border);
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 1px solid var(--color-input-border);
    }

    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
}
