.root {
  background-color: var(--color-content);

  display: flex;
  flex-direction: column;
  gap: 40px;

  height: 100%;

  padding: 15px;

  overflow-y: auto;
}

.logo {
  width: 90%;
  margin: 0 auto;
}

.bottomContent {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  gap: 12px;
}
