@import "../../../ui/media.scss";

.identitficationDocument {
  display: flex;
  border: 1px solid var(--color-progress-border);

  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  gap: 15px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-passive);
  font-size: var(--height-interaction-small);
}

.data {
  display: flex;
  flex-direction: column;
}

.remove {
  margin-left: auto;
}
