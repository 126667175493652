.communication {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: space-between;
  gap: 10px;

  margin-bottom: 40px;
  font-weight: 500;
}

.status {
  display: flex;

  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  font-style: italic;
}

.button {
  //   flex: 1;
  text-align: right;
}

.linkData {
  font-weight: 500;
  font-size: var(--font-size-2);

  display: grid;
  grid-template-columns: 75px 1fr;
  gap: 5px;

  dt {
    color: var(--color-text-passive);
  }
}

.warning {
  font-weight: 500;
  font-size: var(--font-size-2);
  color: var(--color-red-heavy);
  fill: var(--color-red-heavy);

  gap: 5px;
  margin-top: 10px;
}
