.root {
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pass {
  // box-shadow: var(--box-shadow-content);
  border: 1px solid var(--color-silver);
  background-color: var(--color-bg);
  padding: var(--padding-large);
  border-radius: 12px;
}
