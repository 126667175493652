@import "../../../ui/media.scss";

.owners {
  .beneficial-dropdown {
    margin-bottom: 28px;
  }

  .status-boxes {
    padding: 0 0 5px 0;
  }
}
