.root {
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 500px;
  margin: 50px auto;
}

.error_box {
  padding: 10px;
  font-weight: 500;
  background-color: var(--color-red-light);
  color: var(--color-red-extra-heavy);
  border-radius: var(--border-radius-content);
  padding: var(--padding-medium);
  width: 100%;
  text-align: center;
}

.actions {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  button {
    flex: 1 0 200px;
  }
}
