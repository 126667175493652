@import "../../../ui/media.scss";

.update-associate {
  overflow: hidden;
  border-radius: 15px;

  --bottom-action-height: 175px;

  @include tablet {
    --bottom-action-height: 200px;
  }

  @include desktop {
    --bottom-action-height: 100px;
  }

  position: relative;
  .copy-header {
    margin-bottom: 46px;
    @include tablet {
      margin-bottom: 56px;
    }
  }

  .tablet-columns {
    @include desktop {
      & > .column,
      & > div {
        flex: 0 0 calc(33.332% - 20px);
      }
    }
  }

  &.ACCOUNT_HOLDER,
  &.PRIMARY_CONTACT {
    .update-associate-address {
      display: none;
    }
  }

  h2 {
    min-height: 46px;
    width: 100%;

    @include truncate;
  }

  .update-associate-placeholder {
    @include desktop {
      display: none;
    }
  }

  .update-associate-error {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin: 30px 0;

    .button {
      margin: 20px 0 0 0;
    }
  }

  .update-associate-content {
    padding: var(--padding-large);
    background-color: var(--color-bg);
    max-height: calc(100vh - 90px - var(--bottom-action-height));
    overflow-y: auto;
  }

  .update-associate-bottom-actions {
    min-height: var(--bottom-action-height);
    padding: 20px var(--padding-large);
    border-top: 1px solid var(--color-snow-heavy);
    background-color: var(--color-snow);

    display: flex;
    flex-direction: column;

    gap: 10px;

    .button {
      margin: 0;
    }

    .danger-button {
      margin-right: 0;
      @include tablet {
        margin-right: auto;
      }

      .button {
        min-width: 200px;
      }
    }

    .save-associate-action-container {
      flex: 1;
      display: flex;
      align-items: center;

      flex-wrap: wrap-reverse;

      gap: 10px;

      .button {
        flex: 0 0 100%;
      }

      @include desktop {
        .button {
          flex: 0 0 200px;
        }
      }
    }
  }
}
