@import "../../ui/media.scss";

.copy-from {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @include tablet {
    justify-content: flex-end;
  }

  .input.input-dropdown {
    margin: 0 0 0 10px;
    flex: 1;

    @include tablet {
      min-width: 225px;
    }
  }

  .copy-from-icon {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    margin-left: 12px;
  }

  .copy-button-wrapper {
    position: relative;
    color: var(--color-white);
    font-size: var(--font-size-2);
    font-weight: 500;

    .copy-button {
      width: 115px;
    }

    .copy-chip {
      opacity: 0;
      background-color: var(--color-black);
      border-radius: 25px;
      padding: 0 15px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      z-index: -1;
    }

    .copy-chip-animate {
      animation-name: chip-animation;
      animation-duration: 1s;
      z-index: 20;
    }
  }
}

@keyframes chip-animation {
  0% {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  20% {
    opacity: 1;
    top: 50%;
    right: 50%;
    transform: translate(50%, -125%);
  }

  40% {
    opacity: 1;
    top: 50%;
    right: 50%;
    transform: translate(50%, -125%);
  }

  60% {
    opacity: 1;
    top: 50%;
    right: 50%;
    transform: translate(50%, -125%);
  }

  80% {
    opacity: 1;
    top: 50%;
    right: 50%;
    transform: translate(50%, -125%);
  }

  100% {
    opacity: 0;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
}
