@import "../../../../../../../../ui/media.scss";

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.singleContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.header {
  font-weight: 600;
  // color: var(--color-text-passive);
  padding-bottom: 15px;
}

.terminal {
  overflow: hidden;
  position: relative;

  background-color: var(--color-white);
  border-radius: 10px;
  padding: 12px;
  border: 1px solid var(--color-silver);

  cursor: pointer;

  color: var(--color-text-passive);

  transition: all 200ms ease;

  &:hover {
    background-color: var(--link-alt);
    border-color: var(--link);
    color: var(--color-text);
  }
}

.body {
  padding-right: 40px;
  cursor: pointer;
}

.isSelected {
  background-color: var(--link-alt);
  border-color: var(--link);
  color: var(--color-text);
}

.notSelected {
  color: var(--color-text-passive);
}

.isDisabled {
  cursor: not-allowed;
}

.radio {
  position: absolute;
  right: 12px;
}

.image {
  display: flex;
  gap: 6px;
}

.title {
  line-height: normal;
  font-weight: 600;
}
