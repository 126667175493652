@import "./media.scss";

html {
  font-size: 62.5%;
}

body {
  font-size: var(--font-size-3);
  line-height: var(--line-height-default);
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

p {
  margin: 0 0 14px 0;
}

.fs-mini {
  line-height: var(--line-height-s);
  font-size: var(--font-size-1);
}

.fs-small {
  line-height: var(--line-height-s);
  font-size: var(--font-size-2);
}

.fs-large {
  font-size: var(--font-size-4);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  & + p {
    margin: 0 0 14px 0;
  }
}

h1 {
  font-size: var(--font-size-8);
  font-weight: 500;
  margin: 0 0 10px 0;
  letter-spacing: -0.05rem;

  @include tablet {
    font-size: 4rem;
  }
}

h2 {
  font-size: var(--font-size-7);
  font-weight: 500;
  margin: 0 0 10px 0;
  line-height: 5.8rem;
}

h3 {
  font-size: var(--font-size-6);
  line-height: 4.6rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}

h4 {
  font-size: var(--font-size-5);
  line-height: 3.2rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}

h5 {
  font-size: var(--font-size-4);
  font-weight: 600;
  margin: 0 0 10px 0;
}

h6 {
  font-size: var(--font-size-2);
  font-weight: 600;
  margin: 0 0 10px 0;
}

b {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

blockquote,
i {
  font-style: italic;
}

.ellipsis {
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

figure,
blockquote {
  margin: 0;
  line-height: 1.5;
}

figcaption {
  cite {
    font-style: normal;
    font-size: 1.4rem;
    color: var(--color-sub-text);

    @include tablet {
      font-size: 1.6rem;
    }
  }
}

sup {
  vertical-align: top;
}

.text-right {
  text-align: right;
}
