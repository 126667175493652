.language-select {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;

  .flag {
    margin-right: 10px;
  }

  .input.input-dropdown {
    flex: 1;

    select {
      border: 1px solid var(--color-input-border);
    }

    .input-messages,
    .input-label-wrapper {
      display: none;
    }
  }
}
