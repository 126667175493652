.infoBox {
  margin-top: 10px;
  margin-bottom: 38px;
}

.test_website_layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.uploaded_files {
  margin-bottom: 30px;
  & > * {
    margin-bottom: 10px;
  }
}
