@import "../../../../../../../ui/media.scss";

.heading {
  padding: var(--padding-large);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 20px;
  column-gap: 30px;

  padding: var(--padding-large);
}
