@import "../../../ui/media.scss";

.toggle {
  display: block;
  cursor: pointer;

  .toggle-background {
    // transition: background-color 300ms ease;
    // background-color: var(--color-slate);
    height: var(--height-interaction);
    border-radius: calc(var(--height-interaction) / 2);
    padding: 4px;
    width: 60px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px inset;
    border: 1px solid var(--color-input-border);
    background-color: var(--color-delete-bg);
    background-color: #ff96a9;

    @include tablet {
      height: var(--height-interaction);
      border-radius: calc(var(--height-interaction) / 2);
      width: 66px;
    }

    // &:focus-within {
    //   // background-color: var(--color-button-hovered);
    //   // background-color: var(--color-silver);
    //   .toggle-handle {
    //     background-color: #ecc4cb;
    //   }
    // }
  }

  &:focus-within,
  &:hover {
    .toggle-handle {
      background-color: #ecc4cb;
    }
  }

  .toggle-handle {
    height: calc(var(--height-interaction) - 6px);
    width: calc(var(--height-interaction) - 6px);
    border-radius: 50%;
    background-color: white;
    transition: transform 300ms ease, background-color 300ms ease;
    background-color: var(--color-white);
    transform: translateX(0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px, rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 4px 8px,
      rgba(0, 0, 0, 0.2) 0px 8px 16px, rgba(0, 0, 0, 0.2) 0px 16px 32px, rgba(0, 0, 0, 0.2) 0px 32px 64px;

    @include tablet {
      height: calc(var(--height-interaction) - 6px);
      width: calc(var(--height-interaction) - 6px);
    }
  }

  .toggle-label {
    font-size: 1.5rem;
    transition: color 300ms ease;
    // padding: 2px 10px;
    font-weight: 500;
    line-height: 1.4;

    padding: 0 0 4px 0;
    min-width: 0;
    flex: 1 0 auto;
    // width: 0;

    @include tablet {
      // padding: 2px 10px 6px 10px;
      font-size: 1.7rem;
    }
  }

  .toggle-label-tag {
    height: 24px;
    line-height: 24px;
    padding: 0 15px 2px 15px;
    border-radius: 5px;
    @include truncate;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }

  .toggle-label-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  &.small {
    .toggle-background {
      height: var(--height-interaction-small);
      border-radius: calc(var(--height-interaction-small) / 2);
      width: 46px;

      @include tablet {
        height: var(--height-interaction-small);
        border-radius: calc(var(--height-interaction-small) / 2);
        width: 56px;
      }
    }

    .toggle-handle {
      height: calc(var(--height-interaction-small) - 10px);
      width: calc(var(--height-interaction-small) - 10px);

      @include tablet {
        height: calc(var(--height-interaction-small) - 10px);
        width: calc(var(--height-interaction-small) - 10px);
      }
    }
  }

  &.mini {
    .toggle-background {
      height: var(--height-interaction-mini);
      border-radius: calc(var(--height-interaction-mini) / 2);
      width: 36px;
      padding: 3px;

      @include tablet {
        height: var(--height-interaction-mini);
        border-radius: calc(var(--height-interaction-mini) / 2);
        width: 44px;
      }
    }

    .toggle-handle {
      height: calc(var(--height-interaction-mini) - 8px);
      width: calc(var(--height-interaction-mini) - 8px);

      @include tablet {
        height: calc(var(--height-interaction-mini) - 8px);
        width: calc(var(--height-interaction-mini) - 8px);
      }
    }
  }

  &.tiny {
    .toggle-background {
      height: var(--height-interaction-tiny);
      border-radius: calc(var(--height-interaction-tiny) / 2);
      width: 30px;

      @include tablet {
        height: var(--height-interaction-tiny);
        border-radius: calc(var(--height-interaction-tiny) / 2);
        width: 38px;
      }
    }

    .toggle-handle {
      height: calc(var(--height-interaction-tiny) - 6px);
      width: calc(var(--height-interaction-tiny) - 6px);

      @include tablet {
        height: calc(var(--height-interaction-tiny) - 6px);
        width: calc(var(--height-interaction-tiny) - 6px);
      }
    }
  }

  &.is-selected {
    &:focus-within,
    &:hover {
      .toggle-handle {
        background-color: var(--color-button-hovered);
        background-color: var(--color-silver);
        background-color: #7ee2d2;
      }
    }

    .toggle-background {
      background-color: #46beaa;
    }

    .toggle-handle {
      transform: translateX(22px);

      @include tablet {
        transform: translateX(24px);
      }
    }

    &.small {
      .toggle-handle {
        transform: translateX(16px);

        @include tablet {
          transform: translateX(22px);
        }
      }
    }

    &.mini {
      .toggle-handle {
        transform: translateX(12px);

        @include tablet {
          transform: translateX(16px);
        }
      }
    }

    &.tiny {
      .toggle-handle {
        transform: translateX(12px);

        @include tablet {
          transform: translateX(16px);
        }
      }
    }
  }

  &.is-disabled,
  &.is-disabled:hover {
    cursor: not-allowed;

    .toggle-background {
      background-color: var(--color-silver);
    }

    .toggle-handle {
      background-color: var(--color-white);
    }
  }
}
