@import "../../../../../ui/media.scss";

.associate-trace-view {
  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    flex: 1;
    padding: 10px 0;
    font-size: var(--font-size-2);
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    // display: flex;

    // align-items: center;
    // flex-wrap: wrap;
    // width: 100%;

    .log-icon {
      justify-content: center;
    }

    .log-icon,
    .log-recipient {
      display: flex;
      align-items: center;
    }

    .log-recipient {
      justify-content: flex-end;
      padding-right: 20px;
    }

    .log-text {
      display: flex;

      flex-direction: column;
    }

    &:nth-child(odd) {
      background-color: var(--color-snow);
    }

    &:first-child {
      border-top: 1px solid var(--color-input-border);
    }

    .main-log-info {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  // .trace-log-icon {
  //   flex: 0 0 30px;

  //   svg {
  //     position: relative;
  //     top: 4px;
  //   }
  // }

  .trace-log-date {
    font-style: italic;
    flex: 0 0 140px;
  }

  .trace-log-recipient {
    text-align: right;
    margin-left: auto;
    flex: 1;
  }

  .trace-log-location {
    flex: 0 0 50%;
    padding: 0 0 0 30px;
  }

  .trace-log-ip {
    text-align: right;
    flex: 0 0 50%;
  }
}
