@import "../../ui/media.scss";

.acquiring {
  dl {
    margin: 6px 0 0 0;

    @include landscape {
      display: flex;
      flex-wrap: wrap;
    }
  }

  dd,
  dt {
    line-height: 1.4;

    @include landscape {
      flex: 0 0 50%;
      margin: 0 0 6px 0;
    }
  }

  dd {
    margin: 0 0 6px 0;

    @include landscape {
      text-align: right;
    }
  }

  .beacon {
    position: absolute;
    top: 1px;
    right: -25px;
  }

  .selected-acquiring {
    margin: 0 36px 0 0;
    position: relative;
  }
}
