@import "../../ui/media.scss";

.todays-statistics {
  margin: 40px 0;

  ul {
    margin: 10px 0 0 0;

    @include landscape {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .creating {
    background-color: #fdecdc;
    color: #b98351;
    fill: #b98351;

    span {
      background-color: #fbdec3;
    }
  }

  .waiting-merchant {
    background-color: #f6e4eb;
    color: #a9647f;
    fill: #a9647f;

    span {
      background-color: #f5d2e0;
    }
  }

  .waiting-signoff {
    background-color: #ebe5f3;
    color: #836f9f;
    fill: #836f9f;

    span {
      background-color: #dfd4ed;
    }
  }

  .completed-today {
    background-color: #e1e6f2;
    color: #7281a5;
    fill: #7281a5;

    span {
      background-color: #cfd7eb;
    }
  }

  .onboarded-today {
    background-color: #e8f2fe;
    color: #718fb3;
    fill: #718fb3;

    span {
      background-color: #d7e6f9;
    }
  }

  .stats-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 4px 16px -7px var(--color-ghost);
    border-radius: 10px;
    padding: 5px 16px 10px 16px;
    line-height: 1.3;
    height: 100%;

    .icon {
      width: 30px;
      height: 30px;
    }

    @include tablet {
      padding: 10px 16px 16px 16px;
    }

    & > b {
      flex: 1 0 auto;
      padding: 0 10px 0 0;

      @include tablet {
        width: calc(100% - 50px);
      }

      // font-weight: 600;
    }

    & > span {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
      display: block;
      padding: 10px;
      border-radius: 50%;
      display: none;
      position: relative;
      right: -5px;

      @include tablet {
        display: block;
      }
    }

    & > div {
      flex: 1 0 auto;
    }
  }

  li {
    margin: 0 0 20px 0;

    @include landscape {
      flex: 1 0 calc(50% - 16px);
      max-width: calc(50% - 16px);
      margin: 0 0 30px 0;
    }

    @include tablet {
      flex: 1 0 calc(33% - 16px);
      max-width: calc(33% - 16px);
    }

    @include desktop-plus {
      flex: 1 0 calc(20% - 25px);
      max-width: calc(20% - 25px);
    }
  }

  strong {
    font-size: 60px;
    line-height: 60px;
  }
}
