@import "../../../ui/media.scss";

.root {
  margin: 40px auto;
  // max-width: $tablet;
  position: relative;

  background-color: var(--color-warning-light);
  padding: var(--padding-large);
  border-radius: 8px;

  overflow: hidden;

  border: 1px solid var(--color-warning-heavy);

  .title {
    margin-bottom: 16px;
  }

  .link {
    display: inline;
    font-weight: 500;
  }
}
