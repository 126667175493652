@import "../../../../ui/media.scss";

.icpp-description {
  .icpp-desc-table {
    @include tablet {
      display: flex;
    }
  }

  .center {
    @include tablet {
      align-self: center;
      padding: 0 10px;

      span {
        display: block;
        margin: 0 0 4px 0;
      }
    }
  }

  .icpp-desc-item {
    padding: 8px 10px;
    background-color: var(--color-white);
    border-radius: 5px;
    border: 1px solid var(--color-smoke);

    & > div:last-child {
      color: var(--color-slate);
    }

    b {
      white-space: nowrap;
    }
  }
}
