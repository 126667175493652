@import "../../../ui/media.scss";

.filter-view {
  min-height: inherit;

  .filter-search-wrapper {
    margin-left: auto;
  }

  .filter-view-filter-row {
    display: flex;
    gap: 10px;
  }

  .filter-view-filter-active-row {
    min-height: 40px;
    display: flex;
    gap: 10px;
  }

  .filter-table-wrapper {
    overflow-x: auto;
    scroll-behavior: smooth;

    .filter-table {
      border-collapse: initial;
      width: 100%;
      max-width: $desktop-plus;
      box-shadow: rgba(145, 158, 171, 0.16) 0 40px 80px;
      // border-radius: 10px;
      padding-bottom: 10px;

      tbody {
        width: 100%;
      }
    }
  }

  .retry-pending,
  .retry-error {
    padding: 40px 0;
    text-align: center;
  }

  .sortfield-button {
    font-weight: 600;
    color: inherit;

    .sortable-cell {
      position: relative;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 50%;
        left: -22px;
        transform: translateY(-50%);
      }

      .icon-wrapper-rotated {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

#contract-backdrop {
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: var(--color-black);
}

.filter-view {
  &.has-open-menu {
    #contract-backdrop {
      opacity: 0.6;
      pointer-events: all;
    }
  }
}

.filter-row-empty {
  background-color: white;
  width: 100%;
  border: 1px solid var(--color-progress-border);
  border-radius: 0 0 10px 10px;
  padding: 20px 15px;

  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: var(--color-text-passive);
}
