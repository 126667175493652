@import "../../../ui/media.scss";

.contract-edit-vertical-menu {
  padding-top: 20px;
}

.contract-edit-menu-links {
  padding-left: 10px;

  .menu-header {
    opacity: 0.75;
    text-transform: uppercase;
    color: var(--color-text-passive);
    font-weight: 600;
    font-size: var(--font-size-2);
  }

  .menu-list {
    padding: 0 0 20px 0;
  }

  .anchor-validity {
    .icon {
      margin: 0;
    }
  }

  li {
    display: block;
    margin-bottom: 5px;
  }

  .beacon {
    visibility: hidden;

    &.has-required-information,
    &.has-missing-information {
      visibility: visible;
    }
  }
}
