.image-toggler {
  .image-toggler__image-wrapper {
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
  }

  .image-toggler__thumbnails {
    display: flex;
    justify-content: center;
    padding: 12px;
    margin: 4px;

    button {
      border: 0;
      background: transparent;
      margin: 0 8px;
      border: 4px solid transparent;
      border-radius: 12px;
      position: relative;

      img {
        max-width: 100%;
        object-fit: cover;
        width: 64px;
        height: 64px;
        border-radius: 10px;
      }

      &.active {
        &::after {
          content: "";
          height: 12px;
          width: 12px;
          background-color: var(--color-button);
          border-radius: 50%;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
