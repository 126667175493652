.file-upload {
  position: relative;
  display: flex;
  width: 100%;

  .progress,
  .file-upload-button {
    height: 42px;
  }

  .file-upload-button {
    flex: 0 0 60px;
  }

  .file-upload-body {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
    min-height: 50px;
  }

  .progress {
    position: absolute;
    right: 0;
    top: -5px;
    width: calc(100% - 60px);
    pointer-events: none;
    background-color: var(--color-progress-bar-bg);
    padding: 5px;
    opacity: 0;
    transition: opacity ease 200ms;
  }

  &.is-pending {
    .progress {
      opacity: 1;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
