@import "../../../ui/media.scss";

.pricing {
  dl {
    margin: 6px 0 0 0;

    @include landscape {
      display: flex;
      flex-wrap: wrap;
    }
  }

  dd,
  dt {
    line-height: 1.4;

    @include landscape {
      flex: 0 0 50%;
      margin: 0 0 20px 0;
    }
  }

  dd {
    margin: 0 0 20px 0;

    @include landscape {
      text-align: right;
    }
  }

  .disclaimer {
    font-size: 0.8em;
    margin: 12px 0 0 0;
  }
}
