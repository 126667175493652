.admin-pricing-page {
  article {
    max-width: 1024px;
  }

  li {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
  }

  .flag {
    margin: 0 16px 0 0;
  }
}
