.body {
  max-width: 900px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
}

.search {
  flex-basis: 350px;
}
