@import "../../../../../../ui/media.scss";

.root {
  position: fixed;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, var(--color-bg) 100%);
  height: 100px;
  bottom: 0;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  left: 0;

  @include desktop {
    left: var(--vertical-menu-width);
    width: calc(100% - var(--vertical-menu-width));
  }
}

.buttons {
  width: 90%;
  max-width: $tablet;
  margin: 0 auto;
  display: flex;
  gap: 20px;

  & > * {
    flex: 1;
  }
}
