@import "../../ui/media.scss";

.app .admin-page {
  .bottom-content {
    margin-top: auto;

    display: flex;
    flex-direction: column;

    gap: 10px;
  }
}
