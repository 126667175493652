.root {
  display: flex;
  flex-direction: column;
}

.h4 {
  font-size: 2.4rem;
  line-height: normal;
  font-weight: 900;
  margin-bottom: 10px;
}

.buttons {
  margin-top: 30px;

  display: flex;
  align-items: center;
  gap: 20px;
}
