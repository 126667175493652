@import "../../../ui/media.scss";

.offering-terminals {
  @include tablet {
    display: flex;
    gap: 40px;
    max-width: 700px;
    margin: 0 auto;
  }

  &.is-centered {
    @include tablet {
      justify-content: center;
    }
  }

  .image {
    background-color: transparent;
  }

  .package-terminal {
    margin: 0 0 10px 0;

    @include tablet {
      flex: 1 0 auto;
      flex: 1;
      max-width: 33%;
    }

    .bundle-image {
      padding: 16px 40px 10px 0;
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  .package {
    display: block;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &:focus-within {
      .as-ghost {
        box-shadow: var(--color-button-shadow-transparent);
        background-color: var(--link-alt);
      }
    }
  }

  .offer {
    overflow: hidden;
    text-align: left;
    position: relative;
    color: var(--color-text);
    flex: 1 0 auto;
    transition: background-color 300ms ease, box-shadow 300ms ease;
    display: flex;
    flex-direction: column;

    &.is-active {
      background-color: var(--link-alt);
      .active-notifier {
        transform: translateX(0);
      }
    }
  }

  .active-notifier {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50px);
    transition: transform 300ms ease;

    .icon {
      margin: 2px 0 0 22px;
      fill: var(--color-white);
      z-index: 2;
      position: relative;
    }
  }

  .active-notifier-bg {
    position: absolute;
    background-color: var(--color-link);
    width: 50px;
    height: 100px;
    transform-origin: top left;
    transform: rotate(-45deg);
    z-index: 1;
  }

  .offer.is-disabled {
    .active-notifier-bg {
      background-color: var(--color-silver);
    }
  }

  .package {
    .radio {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }
}
