.timeline-event-merchant-confirmation {
  .header {
    display: flex;
    flex-direction: column;
  }
  .event-button--right {
    margin-left: auto;
    position: relative;
    margin-top: -8px;
  }
}
