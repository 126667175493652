.root {
  position: relative;
}

.image {
  width: 70px;
}

.icon {
  position: absolute;
  background-color: var(--color-wl-blue);
  color: white;
  fill: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 5;
  bottom: 0;
  right: 0;
}

.is_pending {
  background-color: var(--color-progress-border);
}

.is_error {
  background-color: var(--color-red-heavy);
}

.is_success {
  background-color: var(--color-green-heavy);
}
