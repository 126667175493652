.header {
  line-height: normal;
  font-weight: 600;
  padding-bottom: 20px;
  // color: var(--color-text-passive);
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
