.expiry-checkbox {
  display: none !important;
}

.id-card-input {
  .input-frame {
    z-index: 10; // needed otherwise this will block the input from being edited
  }

  .positive-attention > .input-frame:after {
    content: "";
    box-shadow: 0 0 6px 3px var(--link-hovered);
    transition: opacity 800ms ease 800ms;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation-duration: 700ms;
    animation-iteration-count: 15;
    animation-direction: alternate;
    animation-name: pulse;
    opacity: 0;
    border-radius: 5px;

    z-index: -1; // needed otherwise this will block the input from being edited
  }

  .show-pulse {
    animation-name: pulse;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
