@import "../../ui/media.scss";

.completed-contracts-page {
  background-color: var(--color-progress-bar-bg);
  padding: 30px 0;

  .completed-contract-second-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
  }

  .status-boxes {
    margin: 10px 0;
  }

  dl {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .copy-link {
    position: fixed;
    bottom: 30px;
  }

  .content-box-wrapper {
    display: flex;
    flex-direction: column;
  }

  .content-box {
    flex: 1;
  }

  .status-list {
    dt,
    dd {
      display: flex;
      align-items: center;
    }

    dt {
      font-style: italic;
      color: var(--color-text-passive);
      gap: 15px;

      font-weight: 500;

      svg {
        color: var(--color-link);
        fill: var(--color-link);
      }

      margin: 0 0 5px 0;
      @include desktop {
        flex: 0 0 225px;
        margin: 0 0 24px 0;
      }
    }

    dd {
      margin: 0 0 24px 0;

      @include desktop {
        flex: 0 0 auto;
        width: calc(100% - 225px);
      }
    }
  }

  .pass-info-list {
    dt {
      font-style: italic;
      color: var(--color-text-passive);
      font-weight: 500;

      flex: 0 0 150px;
    }

    dd {
      flex: 0 0 auto;
      width: calc(100% - 150px);

      margin: 0 0 24px 0;
    }
  }
}
