.root {
  & > * {
    padding: var(--padding-large);
  }
}

.cashless_header {
  background-color: rgb(203 229 225);

  font-weight: 500;
  font-style: italic;
  padding: calc(var(--padding-large) / 2) var(--padding-large);
}
