.status-box {
  position: relative;
  padding: var(--padding-small);

  border-radius: 8px;

  // border: 1px solid black;

  display: flex;

  align-items: center;

  .small-box-size {
    max-width: 350px;
  }

  .medium-box-size {
    max-width: 550px;
  }

  .large-box-size {
    max-width: 800px;
  }

  .fullwidth-box-size {
    width: 100%;
  }

  .status-box-message {
    position: relative;

    margin: 0 var(--interaction-size);
    padding: 0 0 0 var(--padding-small);

    line-height: 1.5;
    font-weight: 500;

    .status-box-text {
      display: flex;
      align-items: center;
    }
  }

  &.default-box {
    padding-left: 0;
    padding-right: 0;

    .status-box-message {
      padding: 0;
      margin: 0;
    }
  }

  .status-box-close {
    position: absolute;
    right: calc((-1 * var(--interaction-size)));
    top: 50%;
    transform: translateY(-50%);
  }

  .status-box-icon {
    position: absolute;
    left: calc((-1 * var(--interaction-size)));
    top: 50%;
    transform: translateY(-50%);
  }

  .icon {
    width: var(--interaction-size);
    height: var(--interaction-size);
    display: none;
  }

  &.error-box {
    background-color: var(--color-red-light);
    border-color: var(--color-red);
    color: var(--color-red-extra-heavy);

    .icon-error-outline {
      fill: var(--color-red-heavy);
      display: block;
    }
  }

  &.success-box {
    background-color: var(--link-alt);
    border-color: var(--color-link);
    color: var(--color-success-text);

    .icon-checkmark {
      fill: var(--color-link);
      display: block;
    }
  }

  &.warning-box {
    background-color: var(--color-warning-light);
    border-color: var(--color-warning);
    color: var(--color-warning-text);

    .icon-warning-outline {
      fill: var(--color-warning-heavy);
      display: block;
    }
  }

  &.tip-box {
    background-color: var(--color-snow-light);
    border: none;
    color: var(--color-text-passive);

    .icon-warning-outline {
      width: var(--interaction-size);
      height: var(--interaction-size);
      fill: var(--color-text-passive);
      display: block;
    }
  }

  &.info-box {
    background-color: var(--color-info-light);

    color: var(--color-info-text);

    .info-icon {
      width: var(--interaction-size);
      height: var(--interaction-size);
      fill: var(--color-info);
      display: block;
    }
  }
}

.margins {
  margin: 0 var(--padding-small);
}

.status-box-with-border {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-snow-heavy);
}

.status-box-without-padding {
  padding: 0;
}
