$link-width: 3px;
$gap: 8px;
$icon-mini: 22px;

.contract-edit-menu-item {
  position: relative;

  button {
    // height: 40px;
  }

  .active-indicator {
    position: absolute;
    // left: 28px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 18px;
    width: 3px;
    background-color: var(--color-link);
    border-radius: 4px;
  }

  .as-link {
    position: relative;
    padding: 0 0 0 calc($gap * 2 + $link-width + $icon-mini / 2);
    text-align: left;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;

    .anchor-validity {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .anchor-name {
      // @include truncate;
      padding-left: $gap;
    }
  }
}
