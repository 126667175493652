.root {
  border-radius: var(--border-radius-content);
  overflow: hidden;
  font-weight: 500;
  background-color: #e2e9f7;
  margin-bottom: 30px;
}

.header {
  padding: var(--padding-large);
  padding-bottom: calc(var(--padding-large) / 2);
  // background-color: #c5d4f1;
}

.subheader {
  padding: calc(var(--padding-large) / 2) var(--padding-large);

  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  // background-color: #c5d4f1;
  gap: 20px;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--color-text-passive);
  border-bottom: 1px solid #c5d1e9;
}

.body {
  background-color: #e2e9f7;
}

.row {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  padding: calc(var(--padding-large) / 2) var(--padding-large);
  gap: 20px;

  align-items: center;

  border-bottom: 1px solid #c5d1e9;
}

.store {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 24px;
  flex: 0 0 24px;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  gap: 20px;

  font-weight: 600;

  padding: calc(var(--padding-large) / 2) var(--padding-large);
  background-color: #dae3f5;
}
