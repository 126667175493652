.page {
  max-width: 1000px;
  margin: 0 auto;
}

.tablet_columns {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
}

.tablet_columns > div {
  min-width: 48%;
  margin-bottom: 16px;
}

.header {
  border-bottom: 1px solid var(--color-snow-heavy);
  padding: var(--padding-large);
}

.form_body {
  padding: var(--padding-large);
}

.actions {
  border-top: 1px solid var(--color-snow-heavy);
  background-color: var(--color-snow);
  padding: var(--padding-large);

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    min-width: 48%;
  }
}
