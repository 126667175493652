.root {
}

.roles_list {
  margin-top: 5px;

  display: flex;
  gap: 5px;

  color: var(--color-text-passive);
  font-size: var(--font-size-2);
}

.role {
  padding: 4px 8px;
  border-radius: 50px;
  color: var(--color-info-text);
  background-color: var(--color-info-light);
  font-weight: 500;
}
