.associate_data {
  // background-color: var(--link-alt);
  // padding: var(--padding-large);
  border-radius: var(--border-radius-content);

  dl {
    display: grid;
    grid-template-columns: 100px 200px;
    gap: 5px;
    font-weight: 500;

    dd {
      color: var(--color-text-passive);
    }

    dd,
    dt {
      margin: 0;
    }
  }
}
