.badge {
  display: inline-block;
  margin-left: 5px;
  padding: 0.1em 0.5em 0.2em 0.5em;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.05rem;
  border-radius: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 15px;
  background-color: var(--color-link);
  color: var(--color-white);

  &.absolute {
    position: absolute;
    left: 100%;
    top: 0;
  }

  &.inline {
    margin-left: 0;
  }
}
