@import "../../../../ui/media.scss";

.root {
  display: flex;
  flex-direction: column;

  border-radius: var(--border-radius-content);
  margin-bottom: 30px;

  background-color: #f1f6f7;
  overflow: hidden;
}

.heading {
  padding: var(--padding-large);
}

.content {
  padding: var(--padding-large);
}

.auth_fees {
  padding: 0 var(--padding-large) var(--padding-large) var(--padding-large);

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dd,
  dt {
    flex: 0 0 50%;
    margin: 0 0 20px 0;
  }

  dd {
    margin: 0 0 20px 0;

    @include landscape {
      text-align: right;
    }
  }
}

.auth_fees_table {
}
