.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dccvalues {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.values {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.button {
  margin-top: 30px;
}
