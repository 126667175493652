.pricing-heat-map {
  margin: 80px 0 0 0;
  overflow-x: scroll;

  & > table {
    margin: 150px 0;
  }

  th,
  td {
    height: 40px;
    width: 40px;
    position: relative;
  }

  td {
    border: 1px solid var(--color-progress-border);
    border: 1px solid var(--link);

    &.checked {
      background-color: var(--color-progress-border);
      background-color: var(--link-alt);
      background-color: #b9d9d9;
    }
  }

  th:last-child {
    .heatmap-row-price-plan {
      border-bottom: 1px solid var(--color-progress-border);
      border-bottom: 1px solid var(--link);
    }
  }

  .heatmap-row-price-plan {
    -webkit-transform-style: preserve-3d;
    position: absolute;
    transform: translate(40px, 0) rotate(-90deg);
    transform-origin: left bottom;
    white-space: nowrap;
    font-weight: normal;
    display: block;
    max-width: 180px;
    width: 180px;
    text-align: left;
    border-top: 1px solid var(--color-progress-border);
    border-top: 1px solid var(--link);
    padding: 0 0 0 20px;
    line-height: 40px;
  }

  .heatmap-row-name {
    width: 160px;
    max-width: 160px;
    padding: 0 20px 0 0;
    border-left: 0;
    line-height: 40px;
    font-style: italic;
  }
}
