@import "../../../../../ui/media.scss";

.terminals {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
}

.terminal {
  border: 1px solid var(--color-snow-heavy);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 10px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.terminalData {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.amount {
  font-weight: 700;
  margin: 0;
}
