.root {
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;

  border-radius: 10px;

  padding: 12px 16px;
  border: 1px solid var(--color-snow-heavy);
  margin-bottom: 12px;

  transition: all 0.1s ease;

  &:hover {
    background-color: var(--color-snow);
  }
}

.title {
  font-weight: 500;
}

.headerOpen {
}

.icon {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid var(--color-snow-heavy);
}

.openIcon {
  height: 40px;
  width: 40px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}
